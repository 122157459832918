import { FETCH_TAGS } from "../actionTypes/tagActionTypes"

const initialState: string[] = []

export default function tagsReducer (state= initialState, action: any){
  switch(action.type) {
    case FETCH_TAGS:
      return action.payload
    default:
      return state
  }
}