import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import { ModificationOptions } from '../../components/analysis/modifications/ModificationDialog';
import ModificationCardComponent from '../../components/analysis/modifications/ModificationCardComponent';
import NewModificationCard from '../../components/analysis/modifications/NewModificationCard';
import { FitType } from '../../models/Fit';
import ModificationDialogContainer from './ModificationDialogContainer';
import { ModificationType } from '../../models/Analysis';

export interface ModificationsContainerProps {
  fit: FitType | null
}
 
const ModificationsContainer: React.FC<ModificationsContainerProps> = (props) => {
  const classes = useStyles()
  const {fit} = props

  const [optionsDialog, setOptionsDialog] = useState<ModificationOptions | null>(null)

  const handleOpenDialog = () => {
    if (fit) {
        setOptionsDialog({
          fit: fit
        })
    }
  }

  const handleOpenEditDialog = (modification: ModificationType) => {
    if (fit) {
      setOptionsDialog({
        modification: modification,
        isEdit: true,
        fit: fit
      })
    }
  }

  const handleCloseDialog = () => {
    setOptionsDialog(null)
  }

  return ( 
    <div className={classes.container2}>
      {
        optionsDialog && <ModificationDialogContainer data={optionsDialog} onClose={handleCloseDialog}/>
      }
      {fit && !fit.analysis?.finished && <NewModificationCard onAddNew={handleOpenDialog} />}
      {
        fit?.analysis?.modifications.map(modification => (
          <ModificationCardComponent fit={fit} modification={modification} onEdit={handleOpenEditDialog} key={modification.id}/>
        ))
      }
    </div>
  );
}
const useStyles = makeStyles(theme => createStyles({
  container2: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: 'center'
  },
  card: {
    marginRight: 20,
    [theme.breakpoints.down('md')]: {
      marginRight: 10,
    }
  },
}))

export default ModificationsContainer