import { createStyles, TableRow, Theme, withStyles } from "@material-ui/core";

const GenericTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor:'pointer',
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:hover': {
        backgroundColor: theme.palette.action.selected,
      }
    },
  }),
)(TableRow);
export default GenericTableRow