import React from 'react'
import { Card, CardContent, createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { AddOutlined } from '@material-ui/icons';

export interface NewModificationCardProps {
  onAddNew: () => void
}
 
const NewModificationCard: React.FC<NewModificationCardProps> = (props) => {
  const {onAddNew} = props
  const classes = useStyles()

  const handleAddNew = () => {
    onAddNew()
  }

  return (
    <Card className={clsx([classes.root])} onClick={handleAddNew}>
      <CardContent >
        <AddOutlined fontSize="large" color="primary"/>
      </CardContent>
    </Card>
  );
}

const useStyles = makeStyles(theme => createStyles({
  root: {
    display: 'flex',
    height: 100,
    width: 100,
    justifyContent: 'center',
    alignItems: 'center',
    "&:hover": {
      cursor: "pointer",
      opacity: 0.8
    },
    maxWidth: 345,
    marginRight: 20,
    [theme.breakpoints.down('md')]: {
      marginRight: 10,
    }
  },
}))
 
export default NewModificationCard;