import React, { useState } from 'react'
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { FormSessionType } from '../models/Session'
import GenericDialog from './generics/GenericDialog'
import DateFnsUtils from '@date-io/date-fns';
import { Button, Card, CardContent, Checkbox, createStyles, Divider, FilledInput, FormControl, FormControlLabel, InputAdornment, makeStyles, MenuItem, Radio, RadioGroup, Select, TextField, Tooltip, Typography } from '@material-ui/core';
import { AppointmentModel } from '@devexpress/dx-react-scheduler';
import { WarningOutlined } from '@material-ui/icons';
import { isEmptyIntersection } from '../utils/date';
type SessionDialogProps = {
  open: boolean,
  onClose: () => void
  day?: Date
  onSubmitSesion: (session: FormSessionType[]) => void
  loading: boolean,
  appointments: AppointmentModel[]
}
type RepeateType = 'day' | 'week' | 'month'

function SessionDialog(props: SessionDialogProps) {
  const { onSubmitSesion, loading, day: selectedDay, appointments } = props
  const classes = useStyles()

  const defaultDate = selectedDay ?? new Date()
  const defautDateEnd = (new Date(defaultDate))
  defautDateEnd.setHours(defaultDate.getHours() + 2)

  const [start, setStart] = useState<Date | null>(defaultDate);
  const [end, setEnd] = useState<Date | null>(defautDateEnd);
  const [isRepeat, setIsRepeat] = useState(false)
  const [repeateType, setRepeateType] = useState<RepeateType>('day')
  const [repeateEvery, setRepeateEvery] = useState(1)
  const [repeateEndType, setRepeateEndType] = useState(ON);
  const [endRepeateDate, setEndRepeateDate] = useState<Date>(new Date());
  const [ocurrencies, setOcurrencies] = useState<number>(10);

  const isValid = React.useMemo(() => {
    if (start && end) {
      if (start.getTime() > end.getTime()) return false
      if (!isRepeat) return validationDates(appointments, repeateType, repeateEvery, start, end, 1)

      if (repeateEndType === ON)
        return validationDates(appointments, repeateType, repeateEvery, start, end, ocurrencies)
      else {
        if (start.getTime() > endRepeateDate.getTime()) return false
        var startParsed = new Date(start)
        var endParsed = new Date(end)
        while (startParsed.getTime() < endRepeateDate.getTime()) {
          let bool = validationDates(appointments, repeateType, repeateEvery, startParsed, endParsed, 1)
          if (!bool) return false
          startParsed = getEndDate(repeateType, repeateEvery, startParsed, 1)
          endParsed = getEndDate(repeateType, repeateEvery, endParsed, 1)
        }
        return true
      }
    } else
      return false
  }, [appointments, repeateType, repeateEvery, start, end, ocurrencies, isRepeat, endRepeateDate, repeateEndType]);

  const handleSubmit = React.useCallback(() => {
    if (!start || !end) return
    if (!isRepeat)
      onSubmitSesion([{
        startDate: start,
        endDate: end
      }])
    else {

      const endDate = repeateEndType === ON ? getEndDate(repeateType, repeateEvery, start, ocurrencies) : endRepeateDate
      var startDateVariable = new Date(start)
      var endDateVariable = new Date(end)
      const sessions = []

      while (startDateVariable.getTime() < endDate?.getTime()) {
        sessions.push({
          startDate: startDateVariable,
          endDate: endDateVariable
        })
        startDateVariable = getEndDate(repeateType, repeateEvery, startDateVariable, 1)
        endDateVariable = getEndDate(repeateType, repeateEvery, endDateVariable, 1)
      }
      onSubmitSesion(sessions)
    }
  }, [start, end, repeateEndType, repeateType, ocurrencies, endRepeateDate, repeateEvery, onSubmitSesion, isRepeat])

  const handleSetOcurrencies = React.useCallback((string: string) => {
    const number = Number(string)
    if (number > 0) setOcurrencies(number)
  }, [setOcurrencies])

  const handleSetDay = React.useCallback((date: Date | null) => {
    if (!date) return
    setStart(date)
    var endDate = new Date(date)
    if (end) {
      endDate.setHours(end.getHours())
      endDate.setMinutes(end.getMinutes())
    }
    setEnd(endDate)

  }, [end, setStart, setEnd])

  return <GenericDialog
    onClose={props.onClose}
    open={props.open}
    title='Add Session'

  >
    <div className={classes.dialogContainer}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className={classes.dayContainer}>
          <KeyboardDatePicker
            className={classes.dayInput}
            value={start}
            onChange={handleSetDay}
            disableToolbar
            variant="inline"
            format="MM-dd-yyyy"
            margin="normal"
            label="Day"
            error={!!start && start.getTime() < Date.now()}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </div>
        <Divider />
        <div className={classes.dayContainer}>
          <KeyboardTimePicker
            className={classes.timeInput}
            margin="normal"
            label="Start time"
            value={start}
            onChange={date => setStart(date)}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
          />

          <KeyboardTimePicker
            className={classes.timeInput}
            margin="normal"
            label="End time"
            error={!!start && !!end && start.getTime() >= end.getTime()}
            value={end}
            onChange={date => setEnd(date)}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={isRepeat}
                onChange={(event, checked) => setIsRepeat(checked)}
                name="repease"
                color="primary"
              />
            }
            label="Repeate event"
          />
        </div>
        {isRepeat && <Card variant='outlined'>
          <CardContent>

            <div className={classes.repeateEveryContainer}>
              <Typography>Repeate every</Typography>
              <TextField
                type='number'
                placeholder={repeateType}
                value={repeateEvery}
                onChange={event => setRepeateEvery(Number(event.target.value))} />
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={repeateType}
                onChange={(event) => setRepeateType(event.target.value as RepeateType)}
              >
                <MenuItem value={'day'}>Day(s)</MenuItem>
                <MenuItem value={"week"}>Week(s)</MenuItem>
                <MenuItem value={"month"}>Month(s)</MenuItem>
              </Select>
            </div>
            <div>
              <Typography>End repeat</Typography>
            </div>
            <div>
              <RadioGroup aria-label="gender" name="gender1" value={repeateEndType} onChange={event => setRepeateEndType(event.target.value)}>
                <table>
                  <tr>
                    <td><FormControlLabel value={ON} control={<Radio />} label="On" /></td>
                    <td>
                      <FormControl variant="filled">
                        <FilledInput
                          disabled={repeateEndType !== ON}
                          id="filled-adornment-weight"
                          value={ocurrencies}
                          onChange={e => handleSetOcurrencies(e.target.value)}
                          type='number'
                          inputMode='numeric'

                          endAdornment={<InputAdornment position="end">Ocurrence(s)</InputAdornment>}
                          aria-describedby="filled-weight-helper-text"
                          inputProps={{
                            'aria-label': 'weight',
                          }}
                        />
                      </FormControl>
                    </td>
                  </tr>
                  <tr>
                    <td><FormControlLabel value={AFTER} control={<Radio />} label="After" /></td>
                    <td>
                      <KeyboardDatePicker
                        disabled={repeateEndType !== AFTER}
                        value={endRepeateDate}
                        onChange={date => date ? setEndRepeateDate(date) : null}
                        error={repeateEndType === AFTER && !!start && endRepeateDate.getTime() < start.getTime()}
                        disableToolbar
                        variant="inline"
                        format="MM-dd-yyyy"
                        margin="normal"
                        label="Day"
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </td>
                  </tr>
                </table>
              </RadioGroup>

            </div>
          </CardContent>
        </Card>}
        <div className={classes.actionsContainer}>
          {!isValid && <Tooltip title='There are some coincidences'>
            <WarningOutlined color='secondary' />
          </Tooltip>}
          <Button disabled={loading || !isValid} className={classes.actionBtn} variant='outlined' color='secondary' onClick={props.onClose}>Cancel</Button>
          <Button disabled={loading || !isValid} className={classes.actionBtn} variant='outlined' color='primary' onClick={handleSubmit}>Save</Button>
        </div>
      </MuiPickersUtilsProvider>
    </div>
  </GenericDialog>
}

//STYLES
const useStyles = makeStyles(theme => createStyles({
  dialogContainer: {
    padding: 20
  },
  repeateEveryContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  dayContainer: {
    width: '100%',
    display: 'flex',
    marginBottom: 20
  },
  dayInput: {
    margin: 'auto'
  },
  timeInput: {
    margin: 10
  },
  actionsContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  actionBtn: {
    margin: 10
  }

}))

// UTILS
const ON = 'on'
const AFTER = 'after'

/**
 * get date in depence of each param
 * @param repeateType
 * @param repeateEvery
 * @param dateInput
 * @param times
 */
function getEndDate(repeateType: RepeateType, repeateEvery: number, dateInput: Date, times: number) {
  const date = new Date(dateInput)
  switch (repeateType) {
    case 'day':
      date.setDate(date.getDate() + times * repeateEvery)
      break
    case 'week':
      date.setDate(date.getDate() + times * 7 * repeateEvery)
      break
    case 'month':
      date.setMonth(date.getMonth() + times * repeateEvery)
      break
  }
  return date
}
/**
 * funciton for validating daters
 * @param appointments
 * @param repeateType
 * @param repeateEvery
 * @param startDate
 * @param endDate
 * @param times
 */
function validationDates(appointments: AppointmentModel[], repeateType: RepeateType, repeateEvery: number, startDate: Date, endDate: Date, times: number) {

  const dates = Array.from(new Array(times)).map((time, index) => ({
    start: getEndDate(repeateType, repeateEvery, startDate, index),
    end: getEndDate(repeateType, repeateEvery, endDate, index),
  }))
  let coincidences: AppointmentModel[] = []
  const isValid = !dates.some(({ start, end }) => {
    return appointments.some(appointment => {
      let startAppointment = new Date(appointment.startDate)
      let endAppointment = new Date(appointment.endDate)
      const isNotValid = !isEmptyIntersection([start, end], [startAppointment, endAppointment])
      if (isNotValid) coincidences.push(appointment)
      return isNotValid
    })
  })
  console.log(coincidences)
  return isValid
}

export default SessionDialog