import { createAction, Callback } from "./actionsTypes"
import { Dispatch } from 'redux'

export type OptionsSearch<T, ID extends keyof T, APut, Tput, APatch, TPatch, APost, TPost, AGet, AGetById, ARemove> = {
    put: (obj: Tput, args: APut) => Promise<T | null>
    patch: (obj: TPatch, args: APatch) => Promise<T | null>
    post: (obj: TPost, args: APost) => Promise<any>
    get: (args: AGet) => Promise<T[]>,
    getByID: (id: T[ID], args: AGetById) => Promise<T>
    remove: (args: ARemove) => Promise<any>
}
export type RequestType<T> = {
    result: T | null,
    isFetching: boolean,
    error: Error | null,
    invalidate: boolean
}
export type GenericState<T> = {
    objects: {
        [id: string]: RequestType<T>
    }
    requests: {
        [queryString: string]: RequestType<string[]>
    }
}


export enum EnumActionTypes {
    FETCH = 'FETCH',
    FETCH_BY_ID = 'FETCH_BY_ID',
    ERROR = 'ERROR',
    RECEIVE_BY_ID = 'RECEIVE_BY_ID',
    ERROR_BY_ID = "ERROR_BY_ID",
    RECEIVE = "RECEIVE",
    INVALIDATE_ALL = "INVALIDATE_ALL",
    INVALIDATE = "INVALIDATE",
    INVALIDATE_BY_ID = "INVALIDATE_BY_ID",
    REMOVE = "REMOVE",
    REMOVE_ALL = "REMOVE_ALL"
}
function validate<T>(array: (T | null)[]): array is T[] {
    return array.filter(e => e !== null).length === array.length
}

export default function createReducer<Store, T, ID extends keyof T, APut, Tput, APatch, TPatch, APost, TPost, AGet, AGetById, ARemove>(
    name: string,
    id: ID,
    getSelfStore: (store: Store) => GenericState<T>,
    fetcheds: Partial<OptionsSearch<T, ID, APut, Tput, APatch, TPatch, APost, TPost, AGet, AGetById, ARemove>>,
    keyExtractor: {
        get: (args: AGet) => string,
    }) {
    const actionObject = {
        fetch: (args: AGet) => createAction(EnumActionTypes.FETCH, { key: keyExtractor.get(args), name }),
        fetchById: (id: string | number) => createAction(EnumActionTypes.FETCH_BY_ID, { id, name }),
        receiveBYId: (id: string | number, data: T | null) => createAction(EnumActionTypes.RECEIVE_BY_ID, { id, name, data }),
        receive: (data: T[], args: AGet) => createAction(EnumActionTypes.RECEIVE, { key: keyExtractor.get(args), name, data }),
        error: (error: Error, args: AGet) => createAction(EnumActionTypes.ERROR, { key: keyExtractor.get(args), error, name }),
        errorById: (id: string | number, error: Error) => createAction(EnumActionTypes.ERROR_BY_ID, { id, error, name }),
        invalidateAll: () => createAction(EnumActionTypes.INVALIDATE_ALL, { name }),
        invalidate: (args: AGet) => createAction(EnumActionTypes.INVALIDATE, { name, key: keyExtractor.get(args) }),
        invalidateById: (id: string | number) => createAction(EnumActionTypes.INVALIDATE_BY_ID, { name, id }),
        remove: (id: string | number) => createAction(EnumActionTypes.REMOVE, { id, name }),
        removeAll: () => createAction(EnumActionTypes.REMOVE_ALL, { name }),
    }

    const options: OptionsSearch<T, ID, APut, Tput, APatch, TPatch, APost, TPost, AGet, AGetById, ARemove> = {
        get: fetcheds.get ?? (() => Promise.reject('METHOD GET NOT IMPLEMENTED')),
        getByID: fetcheds.getByID ?? (() => Promise.reject('METHOD GET BY ID NOT IMPLEMENTED')),
        remove: fetcheds.remove ?? (() => Promise.reject('METHOD REMOVE NOT IMPLEMENTED')),
        put: fetcheds.put ?? (() => Promise.reject('METHOD PUT NOT IMPLEMENTED')),
        post: fetcheds.post ?? (() => Promise.reject('METHOD POST NOT IMPLEMENTED')),
        patch: fetcheds.patch ?? (() => Promise.reject('METHOD PATCH NOT IMPLEMENTED')),
    }

    type Action = ReturnType<typeof actionObject[keyof typeof actionObject]>
    /**
     * 
     * @param state State
     * @param queryString 
     * @param callback 
     */
    function setGetRequest(state: GenericState<T>, keyFetch: string, callback: () => RequestType<string[]>): GenericState<T> {
        return {
            ...state,
            requests: {
                ...state.requests,
                [keyFetch]: callback()
            }
        }
    }
    function getRequest(state: GenericState<T>, key: string): T[] | null {
        var request = state.requests[key]
        if (request && request.result) {
            const result = request.result.map(e => state.objects[e].result)
            if (validate(result))
                return result
            else
                throw new Error(`There a a error in store: ${name}. The request ${key} don't have all this objects in the store 
                \n ${JSON.stringify(request)} \n ${JSON.stringify(result)}`)
        }
        return null
    }
    function getReduxListener(state: GenericState<T>, key: string) {
        const request = state.requests[key]
        return request ? request.result : null
    }
    function getRequestByID(state: GenericState<T>, id: T[ID]): RequestType<T | null> {
        const request = state.objects[(id as any).toString()]
        if (request) {
            return request
        }
        return {
            isFetching: false,
            result: null,
            error: null,
            invalidate: false
        }

    }
    function isInvalidate(state: GenericState<T>, key: string): boolean {
        var request = state.requests[key]
        if (request)
            return request.invalidate
        return false
    }
    function isInvalidateById(state: GenericState<T>, id: T[ID]): boolean {
        const idany: any = id
        const request = state.objects[idany.toString()]
        if (request) return request.invalidate
        return false
    }
    function isFetching(state: GenericState<T>, key: string,): boolean | null {
        var request = state.requests[key]
        if (request)
            return request.isFetching
        return null
    }
    function isFetchingById(state: GenericState<T>, id: T[ID]): boolean | null {
        const idany: any = id
        const request = state.objects[idany.toString()]
        if (request) return request.isFetching
        return null

    }
    function getAllInfo(state: GenericState<T>, key: string) {
        return state.requests[key] ?? {
            isFetching: true,
            result: null,
            error: null,
            invalidate: false
        }
    }
    function fetch(args: AGet, callback?: Callback<T[] | null>) {
        return (dispatch: Dispatch, getState: () => Store) => {
            const state = getSelfStore(getState())
            const key = keyExtractor.get(args)
            if (isFetching(state, key) === null || isInvalidate(state, key)) {
                dispatch(actionObject.fetch(args))
                options.get(args).then(response => {
                    if (callback) callback(null, response)
                    dispatch(actionObject.receive(response, args))
                }).catch(err => {
                    if (callback) callback(err)
                    dispatch(actionObject.error(err, args))
                })
            }
            else if (callback) callback(null, getRequest(state, key))

        }
    }
    function fetchById(args: AGetById, id: T[ID] | any, callback?: Callback<T | null>) {
        return (dispatch: Dispatch, getState: () => Store) => {
            const state = getSelfStore(getState())
            if (isFetchingById(state, id) === null || isInvalidateById(state, id)) {
                dispatch(actionObject.fetchById(id.toString()))
                options.getByID(id, args).then(response => {
                    if (callback) callback(null, response)
                    dispatch(actionObject.receiveBYId(id.toString(), response))
                }).catch(err => {
                    if (callback) callback(err)
                    dispatch(actionObject.errorById(id.toString(), err))

                })
            } else if (callback) callback(null, getRequestByID(state, id).result)

        }
    }
    function put(args: APut, object: Tput, callback?: Callback<T | null>) {
        return (dispatch: Dispatch) => {
            options.put(object, args).then((response: any) => {
                if (callback) callback(null, response)
                dispatch(actionObject.invalidateAll())
            }).catch(callback)
        }
    }
    function patch(args: APatch, object: TPatch, callback?: Callback<T | null>) {
        return (dispatch: Dispatch) => {
            options.patch(object, args).then((response: any) => {
                if (callback) callback(null, response)
                dispatch(actionObject.invalidateAll())
            }).catch(callback)
        }
    }
    function post(args: APost, object: TPost, callback?: Callback<T>) {
        return (dispatch: Dispatch) => {
            options.post(object, args).then((response: any) => {
                if (callback) callback(null, response)
                dispatch(actionObject.invalidateAll())
            }).catch(callback)
        }
    }
    function remove(id: T[ID] | any, callback?: Callback<any>) {
        return (dispatch: Dispatch) => {
            options.remove(id).then(response => {
                if (callback) callback(null, response)
                dispatch(actionObject.remove(id))

            }).catch(callback)
        }
    }

    const defaultState = { objects: {}, requests: {} }
    /**
     * Reducer REDUX
     * @param state State
     * @param action Actions
     */
    function reducer(state: GenericState<T> = defaultState, action: Action) {
        if (action.name !== name) return state
        switch (action.type) {
            case EnumActionTypes.FETCH:
                let result = state.requests[action.key] ? state.requests[action.key].result : null
                return setGetRequest(state, action.key, () => ({
                    isFetching: true,
                    result,
                    error: null,
                    invalidate: false
                }))
            case EnumActionTypes.FETCH_BY_ID:
                let obj = state.objects[action.id] ?? {}
                return {
                    ...state,
                    objects: {
                        ...state.objects,
                        [action.id]: {
                            ...obj,
                            isFetching: true,
                            error: null,
                            result: obj.result ?? null,
                            invalidate: false
                        }
                    }
                }
            case EnumActionTypes.ERROR:
                return setGetRequest(state, action.key, () => ({
                    ...state.requests[action.key],
                    error: action.error
                }))

            case EnumActionTypes.ERROR_BY_ID:
                return {
                    ...state,
                    objects: {
                        ...state.objects,
                        [action.id]: {
                            ...state.objects[action.id],
                            error: action.error,
                            isFetching: false,
                            invalidate: false,
                        }
                    }
                }
            case EnumActionTypes.RECEIVE:
                return {
                    ...setGetRequest(state, action.key, () => ({
                        ...state.requests[action.key],
                        result: action.data.map(e => (e[id] as { toString: () => string }).toString()),
                        isFetching: false,
                        invalidate: false
                    })),
                    objects: {
                        ...state.objects,
                        ...action.data.reduce((obj, e) => ({
                            ...obj, [(e[id] as { toString: () => string }).toString()]: {
                                isFetching: false,
                                invalidate: false,
                                result: e,
                                error: null
                            }
                        }), {})
                    }
                }
            case EnumActionTypes.RECEIVE_BY_ID:
                return {
                    ...state,
                    objects: {
                        ...state.objects,
                        [action.id]: {
                            ...state.objects[action.id],
                            result: action.data,
                            isFetching: false,
                            invalidate: false,
                            error: null
                        }
                    }
                }
            case EnumActionTypes.INVALIDATE_ALL:
                return {
                    ...state,
                    objects: {
                        ...Object.keys(state.objects).reduce((obj, key) => ({ ...obj, [key]: { ...state.objects[key], invalidate: true } }), {})
                    },
                    requests: Object.keys(state.requests).reduce((obj, key) => ({ ...obj, [key]: { ...state.requests[key], invalidate: true } }), {})
                }
            case EnumActionTypes.INVALIDATE:
                return setGetRequest(state, action.key, () => ({
                    ...state.requests[action.key],
                    invalidate: true
                }))
            case EnumActionTypes.INVALIDATE_BY_ID:
                return {
                    ...state,
                    objects: {
                        ...state.objects,
                        [action.id]: {
                            ...state.objects[action.id],
                            isFetching: false,
                            invalidate: true,
                            error: null
                        }
                    }
                }
            case EnumActionTypes.REMOVE_ALL: {
                return defaultState
            }
            default:
                return state
        }
    }
    return {
        reducer,
        action: {
            fetch,
            fetchById,
            post,
            put,
            patch,
            remove,
            actionObject
        },
        getter: {
            isFetching,
            isFetchingById,
            isInvalidate,
            isInvalidateById,
            getRequest,
            getRequestByID,
            getReduxListener,
            getAllInfo
        }
    }
}