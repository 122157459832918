import { BikeType } from "../models/Bike"

export enum KindEnum {
  AERO = 'AERO',
  MTB = 'MTB',
  PELOTON = 'INDOOR',
  ROAD = 'ROAD',
}
export type MeasurementBikeType = {
  //size?: number,
  frameReach?: number //A
  frameStack?: number //B
  saddleHeight?: number, //C 
  saddleSetbackBrp?: number, //D SADDLE SETBACK (FROM BRP)
  saddleSetbackNose?: number, //E  SADDLE SETBACK FROM SADDLE NOSE
  saddleBrp?: number, //F SADDLE BRP – HOOD BRP (DIRECTLY)
  saddleNose?: number, //G: SADDLE NOSE – HANDLEBAR CENTER (DIRECTLY)
  heightDifferenceBrp?: number//H: HEIGHT DIFFERENCE (SADDLE- HOODS BRP)
  heightDifferenceCentre?: number //I HEIGHT DIFFERENCE (SADDLE – HANDLEBAR CENTER)
  crankLenght?: number,//J
  handlebarWidth?: number //K: HABLDEBAR WIDTH
  brakeLeverWidth?: number //L
  saddleAngle?: number, //M
  brp?: number, //N: BRP (BIOMECHANICAL REFERENCE POINT)
  armrestWidth?: number //O
  extensionLength?: number //P
  extensionWidth?: number //Q
  armrestAngle?: number //R 
  brakeLeverAngle?: number//S
  saddleSetbackSeatPost?: number, //T SADDLE SETBACK (SETBACK POSITION IN THE SEAT POST)
  saddleHeightSeatPost?: number, //U SADDLE HEIGHT (SADDLE HEIGTH POSITION IN THE SEAT POST)
  handlebarHeight?: number //V HANDLEBAR HEIGHT IN THE HANDLEBAR POST
  //saddleNoseCenter: //X
  //saddleTilt?: number,
  //hoodTilt?: number
  //handlebarWidth?: number
  //measurex1?: number,
  //measurex2?: number
}

export const keyMeasure: (keyof MeasurementBikeType)[] = ["armrestAngle", "brakeLeverAngle", "frameReach", "frameStack", "saddleHeight", "saddleSetbackBrp", "saddleSetbackNose", "saddleBrp", "saddleNose", "heightDifferenceBrp", "heightDifferenceCentre", "crankLenght", "handlebarWidth", "brakeLeverWidth", "saddleAngle", "brp", "armrestWidth", "extensionLength", "extensionWidth", "saddleSetbackSeatPost", "saddleHeightSeatPost", "handlebarHeight"]

export const keysBike: (keyof BikeType)[] = ['brand', 'kind', 'reference', 'model']

type Config = {
  [KindEnum.AERO]: { field: keyof MeasurementBikeType, description: string, subDescription: string }[]
  [KindEnum.MTB]: { field: keyof MeasurementBikeType, description: string, subDescription: string }[]
  [KindEnum.PELOTON]: { field: keyof MeasurementBikeType, description: string, subDescription: string }[]
  [KindEnum.ROAD]: { field: keyof MeasurementBikeType, description: string, subDescription: string }[]
}

export const bikeFieldConfig: Config = {
  [KindEnum.AERO]: [
      { field: 'frameReach', description: 'ARMREST REACH', subDescription: '' },
      { field: 'frameStack', description: 'ARMREST STACK', subDescription: '' },
      { field: 'saddleHeight', description: 'SADDLE HEIGHT', subDescription: '' },
      { field: 'saddleSetbackBrp', description: 'SADDLE SETBACK', subDescription: 'FROM BRP' },
      { field: 'saddleSetbackNose', description: 'SADDDLE SETBACK', subDescription: 'FROM SADDLE NOSE' },
      { field: 'saddleBrp', description: 'SADDLE BRP – ARMREST', subDescription: 'DIRECTLY' },
      { field: 'saddleNose', description: 'SADDLE NOSE – ARMREST', subDescription: 'DIRECTLY' },
      { field: 'heightDifferenceBrp', description: 'HEIGHT DIFFERENCE SADDLE - ARMREST', subDescription: '' },
      { field: 'crankLenght', description: 'CRANK LENGTH', subDescription: '' },
      { field: 'saddleAngle', description: 'SADDLE ANGLE', subDescription: '' },
      { field: 'brp', description: 'BRP', subDescription: 'BIOMECHANICAL REFERENCE POINT' },
      { field: 'armrestWidth', description: 'ARMREST WIDTH', subDescription: '' },
      { field: 'extensionLength', description: 'EXTENSION LENGTH', subDescription: '' },
      { field: 'extensionWidth', description: 'EXTENSION WIDTH', subDescription: '' },
      { field: 'armrestAngle', description: 'ARMREST ANGLE', subDescription: '' }],
  [KindEnum.MTB]: [
      { field: 'frameReach', description: 'FRAME REACH', subDescription: '' },
      { field: 'frameStack', description: 'FRAME STACK', subDescription: '' },
      { field: 'saddleHeight', description: 'SADDLE HEIGHT', subDescription: '' },
      { field: 'saddleSetbackBrp', description: 'SADDLE SETBACK', subDescription: 'FROM BRP' },
      { field: 'saddleSetbackNose', description: 'SADDLE SETBACK', subDescription: 'FROM SADDLE NOSE' },
      { field: 'saddleBrp', description: 'SADDLE BRP – HANDLEBAR END', subDescription: '' },
      { field: 'saddleNose', description: 'SADDLE NOSE – HANDLEBAR END', subDescription: 'DIRECTLY' },
      { field: 'heightDifferenceBrp', description: 'HEIGHT DIFFERENCE', subDescription: 'SADDLE- HANDLEBAR END' },
      { field: 'heightDifferenceCentre', description: 'HEIGHT DIFFERENCE', subDescription: 'SADDLE – HANDLEBAR CENTER' },
      { field: 'crankLenght', description: 'CRANK LENGTH', subDescription: '' },
      { field: 'handlebarWidth', description: 'HANDLEBAR WIDTH', subDescription: '' },
      { field: 'brakeLeverWidth', description: 'BRAKE LEVER WIDTH', subDescription: '' },
      { field: 'saddleAngle', description: 'SADDLE ANGLE', subDescription: '' },
      { field: 'brp', description: 'BRP', subDescription: 'BIOMECHANICAL REFERENCE POINT' },
      { field: 'brakeLeverAngle', description: 'BRAKE LEVER ANGLE', subDescription: '' },],
  [KindEnum.PELOTON]: [
      { field: 'saddleHeight', description: 'SADDLE HEIGHT', subDescription: '' },
      { field: 'saddleSetbackBrp', description: 'SADDLE SETBACK', subDescription: 'FROM BRP' },
      { field: 'saddleSetbackNose', description: 'SADDLE SETBACK', subDescription: 'FROM SADDLE NOSE' },
      { field: 'saddleBrp', description: 'SADDLE BRP – HANDLEBAR CENTER', subDescription: 'DIRECTLY' },
      { field: 'saddleNose', description: 'SADDLE NOSE – HANDLEBAR CENTER', subDescription: 'DIRECTLY' },
      { field: 'heightDifferenceCentre', description: 'HEIGHT DIFFERENCE', subDescription: 'SADDLE – HANDLEBAR CENTER' },
      { field: 'saddleAngle', description: 'SADDLE ANGLE', subDescription: '' },
      { field: 'brp', description: 'BRP', subDescription: 'BIOMECHANICAL REFERENCE POINT' },
      { field: 'saddleSetbackSeatPost', description: 'SADDLE SETBACK ', subDescription: '(SETBACK POSITION IN THE SEAT POST)' },
      { field: 'saddleHeightSeatPost', description: 'SADDLE HEIGHT', subDescription: 'SADDLE HEIGTH POSITION IN THE SEAT POST' },
      { field: 'handlebarHeight', description: 'HANDLEBAR HEIGHT IN THE HANDLEBAR POST', subDescription: '' }],
  [KindEnum.ROAD]: [
      { field: 'frameReach', description: 'FRAME REACH', subDescription: '' },
      { field: 'frameStack', description: 'FRAME STACK', subDescription: '' },
      { field: 'saddleHeight', description: 'SADDLE HEIGHT', subDescription: '' },
      { field: 'saddleSetbackBrp', description: 'SADDLE SETBACK', subDescription: 'FROM BRP' },
      { field: 'saddleSetbackNose', description: 'SADDLE SETBACK', subDescription: 'FROM SADDLE NOSE' },
      { field: 'saddleBrp', description: 'SADDLE BRP – HANDLEBAR END', subDescription: '' },
      { field: 'saddleNose', description: 'SADDLE NOSE – HANDLEBAR END', subDescription: 'DIRECTLY' },
      { field: 'heightDifferenceBrp', description: 'HEIGHT DIFFERENCE', subDescription: 'SADDLE- HANDLEBAR END' },
      { field: 'heightDifferenceCentre', description: 'HEIGHT DIFFERENCE', subDescription: 'SADDLE – HANDLEBAR CENTER' },
      { field: 'crankLenght', description: 'CRANK LENGTH', subDescription: '' },
      { field: 'handlebarWidth', description: 'HANDLEBAR WIDTH', subDescription: '' },
      { field: 'saddleAngle', description: 'SADDLE ANGLE', subDescription: '' },
      { field: 'brp', description: 'BRP', subDescription: 'BIOMECHANICAL REFERENCE POINT' }],
}

export const measureBikes:Record<keyof MeasurementBikeType,string> = {
  frameReach:"mm",
  frameStack:"mm",
  saddleHeight:"mm",
  saddleSetbackBrp:"mm",
  saddleSetbackNose:"mm",
  saddleBrp:"mm",
  saddleNose:"mm",
  heightDifferenceBrp:"mm",
  heightDifferenceCentre:"mm",
  crankLenght:"mm",
  handlebarWidth:"mm",
  brakeLeverWidth:"mm",
  saddleAngle:"º",
  brp:"mm",
  armrestWidth:"mm",
  extensionLength:"mm",
  extensionWidth:"mm",
  armrestAngle:"º",
  brakeLeverAngle:"º",
  saddleSetbackSeatPost:"mm",
  saddleHeightSeatPost:"mm",
  handlebarHeight:"mm"
}