import { Table, TableBody, TableCell, TableContainer, Toolbar } from '@material-ui/core';
import React from 'react'
import GenericTableHeader from '../components/generics/GenericTableHeader';
import GenericTableRow from '../components/generics/GenericTableRow';
import useSortTable from '../hooks/useSortTable';
import { ENUM_ROUTES, FitrRouteComponentProps } from '../routes';
import { fitterModel } from '../store';
import SwitchFitter from '../components/SwitchFitter'
import { api } from '../services';
import { FitterType } from '../models/Fitter';

function FittersTableView(props: FitrRouteComponentProps<ENUM_ROUTES.FITTERS>) {
  const { result, setInvalidateAll } = fitterModel.uses.useFetch({})
  const fitters = React.useMemo(() => {
    if (result) return result
    return []
  }, [result]);
  const { sortedData, orderBy, order, handleRequestSort } = useSortTable(fitters, 'id')
  const handleChange = React.useCallback((id: number, field: keyof FitterType, data: boolean) => {
    return api.patchFitter(id, { [field]: data }).then(() => {
      return setInvalidateAll()
    })
  }, [setInvalidateAll])
  return <>
    < Toolbar >
      {/** FIlters */}
    </Toolbar >
    <TableContainer >
      <Table size='small' >
        <GenericTableHeader
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headCells={[{
            id: 'id',
            label: 'Id',
          }, {
            id: 'username',
            label: 'Username'
          }, {
            id: 'isPro',
            label: 'Is Pro'
          }, {
            id: 'deleted',
            label: 'Disabled'
          }]}
        />
        <TableBody>
          {sortedData.map((fitter) => (
            <GenericTableRow key={fitter.id} >
              <TableCell align="left" >{fitter.id}</TableCell>
              <TableCell align="left">
                {fitter.username}
              </TableCell>
              <TableCell align="left">
                <SwitchFitter fitter={fitter} field='isPro' onChangeCheck={handleChange} />
              </TableCell>
              <TableCell align="left">
                <SwitchFitter fitter={fitter} field='deleted' onChangeCheck={handleChange} />
              </TableCell>
            </GenericTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </>
}
export default FittersTableView