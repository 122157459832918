import React from 'react'
import { Button, ButtonProps, createStyles, makeStyles } from '@material-ui/core'

type GenericButtonProps = {
  btnType?: 'cancel' | 'save'
} & ButtonProps

function GenericButton(props: GenericButtonProps) {
  const classes = useStyles(props)
  return <Button className={classes.btn} variant={props.btnType === 'cancel' ? 'outlined' : 'contained'} {...props} />
}
const useStyles = makeStyles(theme => createStyles({
  btn: (props: GenericButtonProps) => ({

  })
}))
export default GenericButton