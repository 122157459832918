import React from 'react'
import {
  Appointments,
} from '@devexpress/dx-react-scheduler-material-ui';
import useCalendarStyles from '../../hooks/useCalendarStyles';
import { FitrAppointmentModel } from '../../models/Session';

function Appointment(props: Appointments.AppointmentProps) {
  const classes = useCalendarStyles()
  const appointment = (props.data as FitrAppointmentModel)
  const booked = appointment.booked
  const notPaid = appointment.fit && appointment.fit.purchase === null
  return <Appointments.Appointment className={!notPaid ? booked ? classes.bookedDay : classes.day : classes.notPaid} {...props} />
}

export default Appointment