import { createStyles, makeStyles, TextField, Theme } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AdjustmentCardComponent from '../../components/analysis/modifications/AdjustmentCardComponent'
import TagsComponent from '../../components/analysis/modifications/TagsComponent'
import { AdjustmentType } from '../../models/Adjustment'
import { adjustmentModel, ReducerType } from '../../store'
import { fetchTagsAction } from '../../store/actions/tagActions'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    },
    adjustmentsContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      marginTop: 20
    }
  }),
);

export interface AdjustmentsContainerProps {
  defaultAdjustment?: AdjustmentType | null,
  onSelectAdjustment: (adjustment: AdjustmentType) => void
}

const AdjustmentsContainer: React.FC<AdjustmentsContainerProps> = (props) => {
  const { defaultAdjustment, onSelectAdjustment } = props
  const classes = useStyles()
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [selectedAdjustment, setSelectedAdjustment] = useState<AdjustmentType | null>(defaultAdjustment ? defaultAdjustment : null)
  const [search, setSearch] = useState('');
  const { result: adjustmentsProps } = adjustmentModel.uses.useFetch({})
  const dispatch = useDispatch()
  const tags = useSelector((state: ReducerType) => state.tags)

  useEffect(() => {
    dispatch(fetchTagsAction())
  }, [dispatch])


  const handleChangeSelectedTags = (tags: string[]) => {
    setSelectedTags(tags)
  }

  const handleSelectAdjustment = (adjustment: AdjustmentType) => {
    setSelectedAdjustment(adjustment)
    onSelectAdjustment(adjustment)
  }

  const adjustments = useMemo(() => {
    var filtered = adjustmentsProps ? [...adjustmentsProps] : []
    if (adjustmentsProps && selectedTags.length > 0) {
      filtered = filtered.filter(adjustment => selectedTags.every(tag => adjustment.tags.includes(tag)))
    }
    if (search)
      filtered = filtered.filter(adjustment => adjustment.title.toUpperCase().indexOf(search.toUpperCase()) >= 0)
    return filtered
  }, [adjustmentsProps, selectedTags, search])

  return (
    <div className={classes.container}>
      <TextField label='Search' variant='outlined' value={search} onChange={(e) => setSearch(e.target.value)} />
      <TagsComponent onChangeFilter={handleChangeSelectedTags} tags={tags} />
      <div className={classes.adjustmentsContainer}>
        {
          adjustments.map(adjustment => (
            <AdjustmentCardComponent
              key={adjustment.id}
              adjustment={adjustment}
              selected={selectedAdjustment?.id === adjustment.id}
              onSelect={handleSelectAdjustment}
            />
          ))
        }
      </div>
    </div>
  );
}

export default AdjustmentsContainer;