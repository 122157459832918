import { SummaryType } from "../../models/Summary";
import {FETCH_SUMMARY, RESET_SUMMARY} from "../actionTypes/summaryActionTypes"

export interface SummaryReducerType {
	[queryString: string]: SummaryType | null;
}

const initialState: SummaryReducerType = {};

export default function summaryReducer(state = initialState, action: any) {
	switch (action.type) {
		case FETCH_SUMMARY:
			return {
				...state,
				[action.queryString]: action.payload,
			};
    case RESET_SUMMARY:
      return initialState
		default:
			return state;
	}
}

