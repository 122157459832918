import { makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react'
import { SummaryType } from '../../models/Summary';

export interface SummaryCardProps {
  title: string,
  selected?: boolean,
  summary: SummaryType,
  onClick: () => void
}
 
const SummaryCard: React.FC<SummaryCardProps> = (props) => {
  const {title, summary, selected, onClick} = props
  const classes = useStyles()

  const handleClick = () => {
    onClick()
  }

  return (
    <div className={`${classes.container} ${selected && classes.selected}`} onClick={handleClick}>
      <Typography className={classes.title} color="primary">{title}</Typography>
      <div className={classes.fitsDoneContainer}>
        <Typography color="secondary" className={classes.text}>{summary.quick}</Typography>
        <Typography className={classes.text}>/</Typography>
        <Typography color="primary" className={classes.text}>{summary.pro}</Typography>
      </div>
      <Typography className={classes.textAmount}>{`${summary.amount}$`}</Typography>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    borderRadius: "50%",
    background: theme.palette.grey[200],
    height: 100,
    width: 100,
    padding: 5,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: 20,
    transition:'background 0.2s',
    "&:hover": {
      background: theme.palette.grey[300],
      cursor: "pointer"
    }
  },
  selected: {
    background: theme.palette.grey[300] + " !important"
  },
  fitsDoneContainer: {
    display: "flex",
    flexDirection: "row"
  },
  text: {
    fontSize: 16,
    margin: 1
  },
  textAmount: {
    fontSize: 16,
  },
  title: {
    textTransform: "uppercase",
    fontSize: 14,
    fontWeight: "bold",
  }
}))
 
export default SummaryCard;