import { MenuItem, Select } from '@material-ui/core';
import React from 'react'
import { fitterModel } from '../../store';

export interface FitterSelectorContainerProps {
  onSelect: (id: number) => void,
  selectedValue: number
}
 
const FitterSelectorContainer: React.FC<FitterSelectorContainerProps> = (props) => {

  const {onSelect, selectedValue} = props
  const { result } = fitterModel.uses.useFetch({})

  const handleSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as number
    onSelect(value)
  }
  
  return ( 
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={selectedValue}
      onChange={handleSelect}
    >
      {result?.map(r => 
        <MenuItem value={r.id} key={r.id}>{r.username}</MenuItem>
      )}
    </Select>
  );
}
 
export default FitterSelectorContainer;