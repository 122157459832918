import { Dispatch } from "react";
import { ReducerType } from "..";
import { SummaryType } from "../../models/Summary";
import { api } from "../../services";
import { FETCH_SUMMARY, RESET_SUMMARY } from "../actionTypes/summaryActionTypes";

export function fetchSummaryAction(queryString: string) {
    return (dispatch: Dispatch<any>,  getState: () => ReducerType) => {
        if (!getState().summary[queryString]) {
          api.getSummary(queryString).then(res => {
            dispatch(fetchSummary(res, queryString))
          })
        }
    }
}

export function resetSummaryAction() {
  return (dispatch: Dispatch<any>,  getState: () => ReducerType) => {
    dispatch(resetSummary())
  }
}

const fetchSummary = (summary: SummaryType, queryString: string) => ({
  type: FETCH_SUMMARY,
  payload: summary,
  queryString: queryString
})

const resetSummary = () => ({
  type: RESET_SUMMARY
})