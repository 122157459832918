import { makeStyles, Theme, Typography } from '@material-ui/core';
import React, { useState } from 'react'
import { bikeFieldConfig, measureBikes } from '../../config/measuresConfig';
import { BikeType } from '../../models/Bike'
import { FitType } from '../../models/Fit';
import InfoTitleComponent from './InfoTitleComponent';

export interface BikeInfoComponentProps {
  bike: BikeType,
  fit: FitType
}

const BikeInfoComponent: React.FC<BikeInfoComponentProps> = (props) => {
  const classes = useStyles()
  const { bike, fit } = props

  const [basicInfoExpand, setBasicInfoExpand] = useState<boolean>(true)
  const [anthropometricExpand, setAnthropometricExpand] = useState<boolean>(true)
  const [bikeInfoExpand, setBikeInfoExpand] = useState<boolean>(true)
  const [measuresExpand, setMeasuresExpand] = useState<boolean>(true)
  const [questionExpand, setQuestionExpand] = useState<boolean>(true);
  return (
    <div className={classes.container}>
      <div className={classes.column}>
        <img src={bike.user.avatar} className={classes.avatar} alt="user-img" />
        <div className={classes.infoBlock}>
          <InfoTitleComponent title={"Basic Info"} expanded={basicInfoExpand} onExpand={() => setBasicInfoExpand(!basicInfoExpand)} />
          {basicInfoExpand &&
            <>
              {<Typography className={classes.textAttributes}>{`Email: ${bike.user.username} ${bike.user.lastName}`}</Typography>}
              {(bike.user.firstName || bike.user.lastName) && <Typography className={classes.textAttributes}>{`Name: ${bike.user.firstName} ${bike.user.lastName}`}</Typography>}
              {bike.user.birthday && <Typography className={classes.textAttributes}>{`Birthday: ${bike.user.birthday}`}</Typography>}
              {bike.user.gender && <Typography className={classes.textAttributes}>{`Gender: ${bike.user.gender}`}</Typography>}
            </>
          }
        </div>
        <div className={classes.infoBlock}>
          <InfoTitleComponent title={"Anthropometric"} expanded={anthropometricExpand} onExpand={() => setAnthropometricExpand(!anthropometricExpand)} />
          {anthropometricExpand &&
            <>
              {bike.user.height && <Typography className={classes.textAttributes}>{`Height: ${bike.user.height} cm`}</Typography>}
              {bike.user.weight && <Typography className={classes.textAttributes}>{`Weight: ${bike.user.weight} kg`}</Typography>}
              {bike.user.crotchHeight && <Typography className={classes.textAttributes}>{`Leg length: ${bike.user.crotchHeight} cm`}</Typography>}
              {bike.user.planarType && <Typography className={classes.textAttributes}>{`Type of planar arch: ${bike.user.planarType}`}</Typography>}
              {bike.user.footHeight && <Typography className={classes.textAttributes}>{`Foot height: ${bike.user.footHeight} cm`}</Typography>}
              {bike.user.footWidth && <Typography className={classes.textAttributes}>{`Foot width: ${bike.user.footWidth} mm`}</Typography>}
            </>
          }
        </div>
      </div>
      <div className={classes.column}>
        <img src={bike.avatar} className={classes.avatar} alt="bike-img" />
        <div className={classes.infoBlock}>
          <InfoTitleComponent title={"Bike Info"} expanded={bikeInfoExpand} onExpand={() => setBikeInfoExpand(!bikeInfoExpand)} />
          {bikeInfoExpand &&
            <>
              {bike.kind && <Typography className={classes.textAttributes}>{`Kind: ${bike.kind}`}</Typography>}
              {bike.brand && <Typography className={classes.textAttributes}>{`Brand: ${bike.brand}`}</Typography>}
              {bike.reference && <Typography className={classes.textAttributes}>{`Reference: ${bike.reference}`}</Typography>}
              {bike.model && <Typography className={classes.textAttributes}>{`Model: ${bike.model}`}</Typography>}
            </>
          }
        </div>
        <div className={classes.infoBlock}>
          <InfoTitleComponent title={"Measures"} expanded={measuresExpand} onExpand={() => setMeasuresExpand(!measuresExpand)} />
          {measuresExpand &&
            bikeFieldConfig[bike.kind].map((fieldConfig, index) => {
              if (bike[fieldConfig.field]) {
                let fieldName = fieldConfig.description + (fieldConfig.subDescription ? fieldConfig.subDescription : "")
                let measureUnit = measureBikes[fieldConfig.field]
                return <Typography key={index} className={classes.textAttributes}>{`${fieldName.toLowerCase()}: ${bike[fieldConfig.field]}${measureUnit}`}</Typography>
              }
              return null
            })
          }
        </div>
        <div className={classes.infoBlock}>
          <InfoTitleComponent title={"Quick Questions"} expanded={questionExpand} onExpand={() => setQuestionExpand(!questionExpand)} />
          {questionExpand &&
            fit.questions.map(question => (
              <div key={question.id}>
                <Typography variant='h6' className={classes.textAttributesTitle}>{question.title}</Typography>
                <Typography variant='body1' className={classes.textAttributes}>{question.value}</Typography>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    padding: 20,
    justifyContent: "center",
  },
  column: {
    width: "30%",
    "&:first-child": {
      marginRight: 20
    },
    "&:last-child": {
      marginLeft: 20
    }
  },
  avatar: {
    width: '100%',
    maxHeight: 250,
    objectFit: "cover"
  },

  textAttributesTitle: {
    fontSize: 12,
    textTransform: "capitalize",

  },
  textAttributes: {
    fontSize: 10,
    textTransform: "capitalize"
  },
  infoBlock: {
    marginTop: 10
  }

}))

export default BikeInfoComponent;