import React from 'react'

type Order = 'asc' | 'desc';
// sorting functions
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  /*if (b[orderBy] > a[orderBy]) {
      return 1;
  }*/
  return 1;
}
function getComparator<T,Key extends keyof T>(
  order: Order,
  orderBy: Key,
): (a: T, b: T) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// HOOK
function useSortTable<T>(array: T[], defaultOrder: keyof T) {

  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof T>(defaultOrder);

  const handleRequestSort = React.useCallback((event: any, key: keyof T) => {
    const isAsc = orderBy === key && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(key);
  }, [orderBy, order])

  const sortedData = React.useMemo<T[]>(() => {
    return stableSort(array, getComparator(order, orderBy))

  }, [array, order, orderBy])

  return { handleRequestSort, sortedData, order, orderBy }
}
export default useSortTable