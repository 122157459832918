import React, { useState } from 'react'
import GenericStepper from '../../components/generics/GenericStepper';
import { ENUM_ROUTES, FitrRouteComponentProps, paths } from '../../routes';
import { api } from '../../services';
import { adminFitsModel, fitModel } from '../../store';
import DiagnosticContainer from './DiagnosticContainer';
import ModificationsContainer from './ModificationsContainer';
import ConfirmDialog from '../../components/generics/ConfirmDialog';
import BikeInfoContainer from './BikeInfoContainer';
import IntroductionContainer from './IntroductionContainer';


export default function AnalysisContainer(props: FitrRouteComponentProps<ENUM_ROUTES.FIT_BY_ID>) {
  const { result: fit, setInvalidate: invalidateFit} = fitModel.uses.useFetchById({}, Number(props.match.params.id))
  const { setInvalidate: invalidateAdminFit} = adminFitsModel.uses.useFetch({})
  const [confirmDialog, setConfirmDialog] = useState<boolean>(false)

  const handleFinishAnalysis = async () => {
    if (fit && fit.analysis) {
      await api.patchFinishAnalysis(fit.analysis.id)
      invalidateFit(fit.id)
      invalidateAdminFit(fit.id)
      props.history.push(paths.fitPath())
    }
    setConfirmDialog(false)
  }

  const handleOpenConfirmDialog = () => {
    setConfirmDialog(true)
  }

  const handleCloseConfirmDialog = () => {
    setConfirmDialog(false)
  }

  return (
  <div>
    <ConfirmDialog
      open={confirmDialog}
      title={"Confirm analysis creation"} 
      description={"Are you sure that you want to finish & close this analysis?"}
      onClose={handleCloseConfirmDialog}
      onSubmit={handleFinishAnalysis}  
    />
    {fit && 
      <>
        <BikeInfoContainer fit={fit} />
        <GenericStepper 
          onFinish={handleOpenConfirmDialog}
          canFinish={fit && !fit.analysis?.finished}
          options={
          [
            {
              title: "Introduction",
              component: <IntroductionContainer fit={fit}/>
            },
            {
              title: "Diagnostics",
              component: <DiagnosticContainer fit={fit}/>
            },
            {
              title: "Modifications",
              component: <ModificationsContainer fit={fit}/>
            },
          ]}
        />
      </>
    }
  </div>
  )
}
