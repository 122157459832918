import React, { useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import { useState } from 'react';

type TagsComponentProps = {
  tags: string[],
  onChangeFilter: (selectedTags: string[]) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.5),
      boxShadow: 'none',
      margin: 0
    },
    chip: {
      margin: theme.spacing(0.5),
    },
  }),
);

export default function TagsComponent(props: TagsComponentProps) {
  const {tags, onChangeFilter} = props
  const classes = useStyles();

  const [selectedTags, setSelectedTags] = useState<string[]>([])

  useEffect(() => {
    onChangeFilter(selectedTags)
  }, [selectedTags, onChangeFilter])

  const handleClick = (label: string) => {
    var arrayAux = []
    const selectedIndex = selectedTags.findIndex(tag => tag === label)
    if (selectedIndex === -1) {
      arrayAux = [...selectedTags, label]
    } else {
      arrayAux = [...selectedTags]
      arrayAux.splice(selectedIndex, 1)
    }
    setSelectedTags(arrayAux)
  };

  return (
    <Paper component="ul" className={classes.root}>
      {tags.map((tag, index) => {
        return (
          <li key={index}>
            <Chip
              label={tag}
              onClick={() => handleClick(tag)}
              className={classes.chip}
              color={selectedTags.find(selectedTag => selectedTag === tag) ? "primary": undefined}
            />
          </li>
        );
      })}
    </Paper>
  );
}