import { createStyles, fade, makeStyles, Table, TableBody, TableCell, TableContainer, Toolbar, Switch, FormControlLabel } from '@material-ui/core'
import React, { useState } from 'react'
import GenericTableRow from '../components/generics/GenericTableRow'
import { FitType, PLAN_ENUM } from '../models/Fit'
import { ENUM_ROUTES, FitrRouteComponentProps, paths } from '../routes'
import { fitModel } from '../store'
import GenericTableHeader from '../components/generics/GenericTableHeader'
import { FilterObject } from '../utils/types'
import useSortTable from '../hooks/useSortTable'
import clsx from 'clsx'

type FitTableType = {
    model?: string,
    kind?: string,
    plan?: string
    finished?: string
    analysis?: any
} & FilterObject<FitType, string | number>

export default function FitView(props: FitrRouteComponentProps<ENUM_ROUTES.ALL_FIT>) {

    const { result: fits } = fitModel.uses.useFetch({})
    const classes = styles()

    const fitMaped = React.useMemo<FitTableType[]>(() => !fits ? [] : fits.filter(fit => fit.paid).map(fit => ({
        ...fit,
        model: fit.bike.model,
        kind: fit.bike.kind,
        finished: !!fit.analysis ? fit.analysis.finished ? 'FINISHED' : 'IN PROGRESS' : ''
    })), [fits])
    const { sortedData, handleRequestSort, order, orderBy } = useSortTable(fitMaped, 'id')

    const handleClickRow = React.useCallback((id: number) => {
        props.history.push(paths.fitByIdPath(id.toString()))
    }, [props])

    const [hideFinished, setHideFinished] = useState(false);

    return <div>
        <Toolbar style={{justifyContent:'right'}}>
            <FormControlLabel
                control={
                <Switch
                    checked={hideFinished}
                    onChange={(event, checked) => setHideFinished(checked)}
                    name="hidefinished"
                    color="primary"
                />
                }
                label="Hide finished fits"
          />
        </Toolbar>
        <TableContainer >
            <Table size='small'>
                <GenericTableHeader
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    headCells={[{
                        id: 'id',
                        label: 'Id',
                        numeric: true
                    },
                    {
                        id: 'planType',
                        label: 'Plan'
                    },
                    {
                        id: 'date',
                        label: 'Purchase date',
                    },
                    {
                        id: 'sessionDate',
                        label: 'Scheduled date',
                    },
                    {
                        id: 'kind',
                        label: 'Bike Kind',
                    },
                    {
                        id: 'model',
                        label: 'Bike Model',
                    },
                    {
                        id: 'finished',
                        label: 'In Progress',
                    }]}
                />
                <TableBody>
                    {sortedData.filter(fit => {
                        console.log(fit.analysis);
                        if (hideFinished && fit.analysis?.finished) {
                            return false;
                        } else {
                            return true;
                        }
                    }).map((fit) => (
                        <GenericTableRow key={fit.id} onClick={() => handleClickRow(Number(fit.id))}>
                            <TableCell align="center" >{fit.id}</TableCell>
                            <TableCell className={clsx([classes.planCell, fit.planType === PLAN_ENUM.PRO ? classes.proCell : classes.quickCell])} align="center" >{fit.planType}</TableCell>
                            <TableCell align="center">
                                {fit.date}
                            </TableCell>
                            <TableCell align="center">
                                {fit.sessionDate ? (new Date(fit.sessionDate)).toLocaleDateString() : ''}
                            </TableCell>
                            <TableCell align="center" >
                                {fit.kind}
                            </TableCell>
                            <TableCell align="center">
                                {fit.model}
                            </TableCell>
                            <TableCell align="center">
                                {fit.finished}
                            </TableCell>
                        </GenericTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

    </div>
}
const styles = makeStyles((theme) => createStyles(
    {
        planCell: {
            color: 'black'
        },
        quickCell: {
            backgroundColor: fade(theme.palette.secondary.light, 0.4),
        },
        proCell: {
            backgroundColor: fade(theme.palette.primary.light, 0.4),
        }
    },

))