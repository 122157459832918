import session, { SessionReducerType } from "./reducers/sessionReducer";
import tags from "./reducers/tagsReducer";
import summary from "./reducers/summaryReducer";
import { createStore } from "./lib";
// redux Middlewares
import logger from 'redux-logger'

//reducer Models
import fitModel from './reducers/fitReducer'
import adminFitsModel from './reducers/adminFitsReducer'
import adminProFitsModel from './reducers/adminProFitsReducer'
import userModel from './reducers/userReducer'
import sessionModel from './reducers/sessionCalendarReducer'
import modificationModel from './reducers/modificationReducer'
import adjustmentModel from './reducers/adjustmentReducer'
import fitterModel from './reducers/fitterReducer'
import bikeModel from './reducers/bikeReducer'
import { SummaryReducerType } from "./reducers/summaryReducer";

export type ReducerType = {
    session: SessionReducerType,
    tags: string[],
    summary: SummaryReducerType
}

export default createStore<ReducerType>({
    session,
    tags,
    summary
}, [logger])

export { fitModel, adminFitsModel, adminProFitsModel, userModel, modificationModel, adjustmentModel, sessionModel, fitterModel, bikeModel }
