import { VideoKeys } from "../models/Analysis"

export const videoKeys: (VideoKeys)[] = ['backView', 'frontView', 'self', 'sideView']
export const imagesKeys: (VideoKeys)[] = ['cleatLeft', 'cleatRight', 'sideWithout']

const fieldDiagnosticConfig:{[K in VideoKeys]:string} = {
  cleatRight:'Right Cleat',
  cleatLeft:'Left Cleat',
  backView:'Back View',
  frontView: 'Front View',
  self:'Self Video',
  sideView:'Side view',
  sideWithout:'Bike Details',
  cleats:'Cleats'
}
export default fieldDiagnosticConfig