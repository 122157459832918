import React from 'react'
import { Divider, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export interface InfoTitleComponentProps {
  title: string,
  expanded?: boolean,
  onExpand: () => void
}
 
const InfoTitleComponent: React.FC<InfoTitleComponentProps> = (props) => {
  const {title, expanded, onExpand} = props
  const classes = useStyles()
  return (
    <div>
      <div className={classes.container}>
        <Typography className={classes.title}>{title}</Typography>
            <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={onExpand}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
      </div>
        <Divider />
      </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "row"
  },
  title: {
    fontWeight: "bold",
    fontSize: 12
  },
  expand: {
    padding: 0,
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}))
 
 
export default InfoTitleComponent;