import { CircularProgress } from '@material-ui/core';
import React from 'react'
import BikeInfoComponent from '../../components/analysis/BikeInfoComponent';
import { FitType } from '../../models/Fit';
import { bikeModel } from '../../store';

export interface BikeInfoContainerProps {
  fit: FitType
}
 
const BikeInfoContainer: React.FC<BikeInfoContainerProps> = (props) => {
  const { fit } = props
  const { result: bike, isFetching: isFetchingBike } = bikeModel.uses.useFetchById({}, fit.bike.id)

  if (isFetchingBike || bike === null) return <CircularProgress />
  return (
    <BikeInfoComponent bike={bike} fit={fit}/>
   );
}
 
export default BikeInfoContainer;