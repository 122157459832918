import React, { FormEvent } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { SignUp } from '../models/SignUp';

type Props = {
    classes: any,
    error?: boolean,
    onSubmit: (signUpData: SignUp) => void,
    icon?: JSX.Element
}

class SignUpView extends React.Component<Props> {
    state = {
        modified: false,
        passwordNotEquals: false
    }

    pass = React.createRef<HTMLInputElement>()
    repeatPassword = React.createRef<HTMLInputElement>()
    description = React.createRef<HTMLInputElement>()
    lastName = React.createRef<HTMLInputElement>()
    firstName = React.createRef<HTMLInputElement>()
    username = React.createRef<HTMLInputElement>()

    onSubmit(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault();
        if (this.pass.current!.value !== this.repeatPassword.current!.value) {
          this.setState({passwordNotEquals: true})
          return
        }
        this.setState({passwordNotEquals: false})
        const username = this.username.current!.value
        const password = this.pass.current!.value
        const description = this.description.current!.value
        const firstName = this.firstName.current!.value
        const lastName = this.lastName.current!.value

        this.props.onSubmit({
          username: username,
          description: description,
          password: password,
          firstName: firstName,
          lastName: lastName
        });
    }

    render() {
        return (
            <React.Fragment>
                <CssBaseline />
                <main className={this.props.classes.layout}>
                    <Paper className={this.props.classes.paper}>
                        <Avatar className={this.props.classes.avatar}>
                            {this.props.icon ? this.props.icon : <LockIcon color='secondary' className={this.props.classes.avatarIcon} />}
                        </Avatar>
                        <Typography variant='h5'>Sign up</Typography>
                        <form className={this.props.classes.form} onSubmit={this.onSubmit.bind(this)}>
                            <FormControl margin='normal' required fullWidth>
                                <InputLabel htmlFor='username'>User name</InputLabel>
                                <Input
                                    onChange={ev => this.setState({ modified: true })}
                                    type="email"
                                    name='username'
                                    autoComplete='username'
                                    inputRef={this.username}
                                />
                            </FormControl>
                            <FormControl margin='normal' required fullWidth>
                                <InputLabel htmlFor='first-name'>First name</InputLabel>
                                <Input
                                    onChange={ev => this.setState({ modified: true })}
                                    name='first-name'
                                    type='text'
                                    inputRef={this.firstName}
                                    autoComplete='current-first-name'
                                />
                            </FormControl>
                            <FormControl margin='normal' required fullWidth>
                                <InputLabel htmlFor='lastName'>Last name</InputLabel>
                                <Input
                                    onChange={ev => this.setState({ modified: true })}
                                    name='lastName'
                                    type='lastName'
                                    inputRef={this.lastName}
                                    autoComplete='current-lastName'
                                />
                            </FormControl>
                            <FormControl margin='normal' required fullWidth>
                                <InputLabel htmlFor='password'>Password</InputLabel>
                                <Input
                                    error={this.state.passwordNotEquals}
                                    onChange={ev => this.setState({ modified: true })}
                                    name='password'
                                    type='password'
                                    inputRef={this.pass}
                                    autoComplete='current-password'
                                />
                            </FormControl>
                            <FormControl margin='normal' required fullWidth>
                                <InputLabel htmlFor='repeat-password'>Repeat password</InputLabel>
                                <Input
                                    onChange={ev => this.setState({ modified: true })}
                                    name='repeat-password'
                                    type='password'
                                    inputRef={this.repeatPassword}
                                    autoComplete='current-repeat-password'
                                />
                            </FormControl>
                            <FormControl margin='normal' required fullWidth>
                                <InputLabel htmlFor='description'>Description</InputLabel>
                                <Input
                                    onChange={ev => this.setState({ modified: true })}
                                    name='description'
                                    type='text'
                                    multiline
                                    inputRef={this.description}
                                    autoComplete='current-description'
                                />
                            </FormControl>
                            {this.props.error && <Typography color='secondary' variant='subtitle2'>Error creating user</Typography>}
                            <Button
                                fullWidth
                                type="submit"
                                variant='contained'
                                color='primary'
                                className={this.props.classes.submit}
                            >
                                Sign up
                            </Button>
                        </form>
                    </Paper>
                </main>
            </React.Fragment>
        );
    }
}
export default withStyles(theme => ({
    layout: {
        width: 'auto',
        display: 'block', // Fix IE11 issue.
        marginLeft: theme.spacing() * 3,
        marginRight: theme.spacing() * 3,
        [theme.breakpoints.up(400 + theme.spacing() * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing() * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing() * 2}px ${theme.spacing() * 3}px ${theme.spacing() * 3}px`,
    },
    avatar: {
        margin: theme.spacing(),
        backgroundColor: theme.palette.primary.main,
    },
    avatarIcon: {
        color: theme.palette.secondary.dark
    },
    form: {
        width: '100%', // Fix IE11 issue.
        marginTop: theme.spacing(),
    },
    submit: {
        marginTop: theme.spacing() * 3,
    },
}))(SignUpView);