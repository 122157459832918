import React, { useState, useEffect } from 'react'
import { Switch, SwitchProps } from '@material-ui/core'
import { FitterType } from '../models/Fitter'
import { KeysOf } from '../utils/types'

type SwitchFitterProps<Field extends keyof FitterType> = {
  fitter: FitterType,
  field: Field,
  onChangeCheck: (id: number, field: keyof FitterType, data: FitterType[Field]) => Promise<any>
} & Omit<SwitchProps, 'onChange'>

function SwitchFitter<Field extends KeysOf<FitterType, boolean>>(props: SwitchFitterProps<Field>) {
  const { fitter, field, onChangeCheck } = props
  const [data, setData] = useState(fitter[field]);
  
  useEffect(() => {
    setData(fitter[field])
  }, [fitter, field])

  const handleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const lastData = data
    setData(checked)
    onChangeCheck(fitter.id, field, checked).catch(() => {
      setData(lastData)
    })
  }, [fitter, data, field, onChangeCheck])

  return <Switch
    checked={data}
    onChange={handleChange}
    color="primary"
    name="checkedB"
    inputProps={{ 'aria-label': 'primary checkbox' }}
    {...props}
  />
}
export default SwitchFitter