import React, { useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import GenericDialog from '../../generics/GenericDialog'
import { FitType } from '../../../models/Fit';
import { ModificationType, PostModificationType } from '../../../models/Analysis';
import { AdjustmentType } from '../../../models/Adjustment';
import GenericStepper from '../../generics/GenericStepper';
import AdjustmentsContainer from '../../../containers/Analysis/AdjustmentsContainer';
import StepsContainer from '../../../containers/Analysis/StepsContainer';
import ModificationResume from './ModificationResume';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 30
    },
    adjustmentsContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center"
    },
    form: {
    },
    input: {
      marginBottom: 20
    },
    media: {
      objectFit: 'contain',
      height: 400,
      marginBottom: 20,
    },
    btnContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: 'flex-end',
    },
    btn: {
      width: 100
    },
    lastBtn: {
      marginLeft: 10
    }
  }),
);

type Common = {
  fit: FitType,
}

type NoEdit = {
  isEdit?: false
}

type Edit = {
  isEdit: true,
  modification: ModificationType
} 

export type ModificationOptions = Common & (NoEdit | Edit)

type ModificationDialogProps = {
  data: ModificationOptions,
  open: boolean,
  onSubmit: (modification: Partial<PostModificationType>) => void,
  onClose: () => void,
}

const TITLE = "Modification"
 
const ModificationDialog: React.FC<ModificationDialogProps> = (props) => {
  const {open, data, onClose, onSubmit} = props
  const [selectedAdjustment, setSelectedAdjustment] = useState<AdjustmentType | null>(data.isEdit ? data.modification.adjustment : null)
  const [steps, setSteps] = useState<string[]>(data.isEdit ? data.modification.steps : [])

  const classes = useStyles()

  const handleSubmit = () => {
    if (selectedAdjustment === null || steps.length === 0) {
      console.error("Adjustments and steps are mandatory")
    } else {
      onSubmit({
        adjustmentId: selectedAdjustment.id,
        steps: steps
      })
    }
  }

  const handleSelectAdjustment = (adjustment: AdjustmentType) => {
    setSelectedAdjustment(adjustment)
  }

  const handleChangeSteps = (steps: string[]) => {
    setSteps(steps)
  }
  const defaultStep = React.useMemo(()=>{
    if(selectedAdjustment?.id) return 1
    return 0
  },[selectedAdjustment])
  return (
      <GenericDialog title={TITLE} open={open} onClose={onClose} fullScreen>
        <div className={classes.container}>
          <GenericStepper 
            defaultStep={defaultStep}
            canFinish={selectedAdjustment && steps.length > 0}
            onFinish={handleSubmit}
            options={
              [
                {
                  title: "Select adjustment",
                  component: <AdjustmentsContainer onSelectAdjustment={handleSelectAdjustment} defaultAdjustment={selectedAdjustment}/>
                },
                {
                  title: "Define steps",
                  component: <StepsContainer onChangeSteps={handleChangeSteps} defaultSteps={steps}/>
                },
                {
                  title: "Resume",
                  component: <ModificationResume steps={steps} adjustment={selectedAdjustment}/>
                },
              ]
            }
          />
        </div>
      </GenericDialog>
  );
}
 
export default ModificationDialog;