import React, { useState } from 'react'
import { makeStyles, TextField, Theme } from '@material-ui/core';
import { FitType } from '../../models/Fit';
import { fitModel } from '../../store';
import { api } from '../../services';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: 'column',
    alignItems: "center"
  },
}))

export interface IntroductionContainerProps {
  fit: FitType
}

const IntroductionContainer: React.FC<IntroductionContainerProps> = (props) => {
  const CHARACTER_LIMIT = 200
  const {fit} = props
  const classes = useStyles()
  const [introduction, setIntroduction] = useState<string>(fit?.analysis?.fitterIntroduction ?? "")
  const {setInvalidate: invalidateFit} = fitModel.uses.useFetchById({}, fit.id)

  const handleChange = (e: React.ChangeEvent<any>) => {
    setIntroduction(e.target.value)
  }

  const handleBlur = async() => {
    var analysis = fit.analysis
    if (analysis && analysis.finished) return
    if (!analysis) {
      analysis = await api.postAnalysis(fit.id)
    }
    if (analysis) {
      api.patchAnalysisWithIntroduction(analysis.id, introduction).then(res => invalidateFit(fit.id))
    }
  }

  return (
    <div className={classes.container}>
      <TextField
        id="outlined-error-helper-text"
        label={`Introduction`}
        inputProps={{maxLength: CHARACTER_LIMIT}}
        fullWidth
        defaultValue={introduction}
        variant="outlined"
        onChange={handleChange}
        helperText={`${introduction.length}/${CHARACTER_LIMIT}`}
        onBlur={handleBlur}
        multiline
      />
    </div>
  );
}

export default IntroductionContainer;
