export type UserType = {
  id: number
  firstName: string,
  username: string,
  lastName: string,
  birthday: string,
  gender: 'M' | 'F'
  height?: number
  weight?: number,
  crotchHeight?: number,
  footHeight?: number
  planarType?: string
  footWidth?: number
  avatar?: string,
  description?: string,
  roles: ROL[]
}

export enum ROL {
  ROLE_ADMIN = "ROLE_ADMIN",
  ROLE_FITTER = "ROLE_FITTER",
}
