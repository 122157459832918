import React, { useState } from 'react'
import { Button, IconButton, makeStyles, TextField, Theme } from '@material-ui/core';
import { AddOutlined } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import { useEffect } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: 'column',
    alignItems: "center"
  },
  btnContainer: {
    display: 'inline-block',
    marginTop: 20,
    marginBottom: 20
  },
  stepsContainer: {
    display: "flex",
    flexDirection: "column",
    width: "60%",
    alignItems: "center"
  },
  stepRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginBottom: 10,
  },
  iconBtn: {
    padding: 0,
    marginLeft: 10
  }
}))

export interface StepsContainerProps {
  defaultSteps?: string[],
  onChangeSteps: (steps: string[]) => void
}
 
const StepsContainer: React.FC<StepsContainerProps> = (props) => {
  const {defaultSteps, onChangeSteps} = props
  const classes = useStyles()
  const [steps, setSteps] = useState<string[]>(defaultSteps? [...defaultSteps] : [])

  const handleChangeStep = (e: any, index: number) => {
    const stepsAux = [...steps]
    stepsAux[index] = e.target.value
    setSteps([...stepsAux])
  }

  const handleAddStep = () => {
    setSteps([...steps, ""])
  }

  const handleDeleteStep = (index: number) => {
    const stepsAux = [...steps]
    stepsAux.splice(index, 1)
    setSteps(stepsAux)
  }

  useEffect(() => {
    onChangeSteps(steps)
  }, [steps, onChangeSteps])

  return (
    <div className={classes.container}>
      <div className={classes.btnContainer}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddOutlined />}
          onClick={handleAddStep}
        >
          Add Step
        </Button>
      </div>
      <div className={classes.stepsContainer}>
      {
        steps.map((step, index) => (
          <div className={classes.stepRow} key={index}>
            <TextField
              id="outlined-error-helper-text"
              label={`Step ${index +1}`}
              fullWidth
              defaultValue={step}
              variant="outlined"
              onChange={(e: any) => handleChangeStep(e, index)}
              multiline
            />
            <IconButton color="secondary" aria-label="delete" onClick={() => handleDeleteStep(index)} className={classes.iconBtn}>
              <DeleteIcon />
            </IconButton>
          </div>
        ))
      }
      </div>
    </div>
  );
}
 
export default StepsContainer;