
export enum ENUM_ROUTES {
  MAIN = 'MAIN',
  CALENDAR = 'CALENDAR',
  ALL_FIT = 'FIT LIST',
  FIT_BY_ID = 'FIT ANALYSIS',
  MANAGE_QUICK_FITS = 'MANAGE QUICK FITS',
  MANAGE_PRO_FITS = 'MANAGE PRO FITS',
  FITTERS = "FITTERS",
  LOGIN = 'LOGIN',
  SIGN_UP = 'SIGN UP',
  SUMMARY = 'SUMMARY'
}
