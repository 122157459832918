import { Avatar, createStyles, makeStyles, Paper, TextField } from '@material-ui/core'
import { ImageSearch } from '@material-ui/icons'
import React, { useRef } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { UserType } from '../models/User'
import { api } from '../services'
import { initAction } from '../store/actions/sessionActions'
import GenericButton from './generics/GenericButton'
import GenericDialog, { GenericDialogProps } from './generics/GenericDialog'

type Props = {
  fitter: UserType
} & Omit<GenericDialogProps, 'title'>

function ModifyFitterDialog(props: Props) {
  const { fitter, onClose } = props
  const [name, setName] = useState(fitter.firstName);
  const [description, setDescription] = useState(fitter.description);
  const [lastName, setLastName] = useState(fitter.lastName);
  const [password, setPassword] = useState<string>("");
  const [errorPassword, setErrorPassword] = useState<boolean>(false);
  const [repeatedPassword, setRepeatedPassword] = useState<string>("");
  const dispatch = useDispatch()
  const fileRef = useRef<HTMLInputElement | null>()
  const [avatarImage, setAvatarImage] = React.useState<string | ArrayBuffer | null>(null);
  const classes = useStyles()

  const handleImage = () => {
    fileRef.current?.click()
  }
  const isChanged = React.useMemo(() => {
    if (!!avatarImage || fitter.firstName !== name || fitter.lastName !== lastName || fitter.description !== description) return true
    else if (password !== '' && password === repeatedPassword) return true
    return false
  }, [fitter, name, lastName, avatarImage, description, password, repeatedPassword])

  const onChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader()
      reader.onload = function (e) {
        if (e.target)
          setAvatarImage(e.target.result)
      }
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  const checkPasswords = () => {
    setErrorPassword(false)
    if (password) {
      if (password === repeatedPassword) {
        return true
      } else {
        setErrorPassword(true)
        return false
      }
    }
    return true
  }

  const handleSubmit = async () => {
    if (!!avatarImage) {
      const formData = new FormData()
      var blob = new Blob([avatarImage]);
      formData.append('file', blob)
      await api.postAvatar(formData)
    }

    if (checkPasswords()){
      const userObject: any = {}
      if (name) userObject.firstName = name
      if (lastName) userObject.lastName = lastName
      if (description) userObject.description = description
      if (password) userObject.password = password

      await api.patchSelfFitter(userObject).then(() => {
        dispatch(initAction())
        onClose()
      })
    }
  }

  return <GenericDialog title='' fullScreen={false} {...props}>
    <Paper className={classes.dialogContainer}>
      <div className={classes.avatarContainer}>
        <div className={classes.avatarWrapper} onClick={handleImage}>
          <Avatar className={classes.avatar} src={avatarImage as any ?? fitter.avatar} alt={props.fitter.firstName} />
          <ImageSearch className={classes.addIcon} />
        </div>
      </div>
      <div className={classes.formContainer}>
        <form>
          <input className={classes.inputFile} ref={ref => fileRef.current = ref} type='file' onChange={onChangeImage} />
          <div className={classes.row}>
            <TextField
              className={classes.input}
              id="name"
              value={name}
              onChange={(event) => setName(event.target.value)}
              name={"title"}
              label="Name"
              variant="outlined"
              defaultValue={fitter.firstName}
              required />
            <TextField
              className={classes.input}
              id="lastName"
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
              name={"title"}
              label="Last Name"
              variant="outlined"
              defaultValue={fitter.firstName}
              required />
          </div>
          <TextField
            className={classes.input}
            id="description"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            name={"Description"}
            label="Description"
            variant="outlined"
            multiline
            defaultValue={fitter.description}
            required
            fullWidth />
            <div className={classes.row}>
              <TextField
                className={classes.input}
                id="password"
                type="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                name={"title"}
                label="Password"
                variant="outlined"
                error={errorPassword}
              />
              <TextField
                className={classes.input}
                type="password"
                id="repeatPassword"
                value={repeatedPassword}
                onChange={(event) => setRepeatedPassword(event.target.value)}
                name={"repeatPassword"}
                label="Repeat password"
                variant="outlined"
                required={!!password}
                error={errorPassword}
              />
          </div>
        </form>
        <div className={classes.row}>
          <GenericButton className={classes.btn} disabled={!isChanged} btnType='save' onClick={handleSubmit} >Save</GenericButton>
          <GenericButton className={classes.btn} disabled={!isChanged} btnType='cancel' onClick={props.onClose} >Cancel</GenericButton>
        </div>
      </div>
    </Paper>
  </GenericDialog>
}

const useStyles = makeStyles(theme => createStyles({
  dialogContainer: {
    padding: 20
  },
  avatarContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 50
  },
  avatarWrapper: {
    position: 'relative',
    cursor: 'pointer',
    '&:hover svg': {
      color: theme.palette.primary.light
    }
  },
  avatar: {
    width: 100,
    height: 100,

  },
  addIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: 'black',
  },
  inputFile: {
    display: 'none'
  },
  formContainer: {
    padding: 5,
  },
  row: {
    display: 'flex',
    marginBottom: 10,
    marginTop: 10
  },
  input: {
    flex: "auto",
    marginRight: 10,
    "&:last-child": {
      margin: 0
    }
  },
  btn: {
    marginRight: 10,
    "&:last-child": {
      margin: 0
    }
  }
}))
export default ModifyFitterDialog