import { createAction, ActionUnion } from "../lib/actionsTypes";
import { Dispatch } from "react";
import { api } from "../../services";
import LocalStorageController from "../../utils/LocalStorageController";
import { UserType } from "../../models/User";
import {fitModel, userModel, modificationModel, adjustmentModel, sessionModel, fitterModel, bikeModel, adminFitsModel} from '../index'
import { resetSummaryAction } from "./summaryActions";

const SET_USER = 'SET_USER'
const SET_USER_ID = "SET_USER_ID"
const CLEAR_USER = 'CLEAR_USER'
const ERROR_USER = 'ERROR_USER'
const LOGGED = 'LOGGED'
const actions = {
    setUser: (user: UserType) => createAction(SET_USER, { user }),
    setUserId: (userId: number, token: string) => createAction(SET_USER_ID, { userId, token }),
    clearUser: () => createAction(CLEAR_USER),
    setError: (error: Error) => createAction(ERROR_USER, { error }),
    setLogged: (isLogged: boolean) => createAction(LOGGED, { isLogged }),
}

export type SessionActions = ActionUnion<typeof actions>

function invalidateStore(){
    return (dispatch: Dispatch<any>) => {
        dispatch(fitModel.action.actionObject.removeAll())
        dispatch(userModel.action.actionObject.removeAll())
        dispatch(modificationModel.action.actionObject.removeAll())
        dispatch(adjustmentModel.action.actionObject.removeAll())
        dispatch(sessionModel.action.actionObject.removeAll())
        dispatch(fitterModel.action.actionObject.removeAll())
        dispatch(bikeModel.action.actionObject.removeAll())
        dispatch(adminFitsModel.action.actionObject.removeAll())
        dispatch(resetSummaryAction())
    }
}

export function logoutAction() {
    return (dispatch: Dispatch<any>) => {
        dispatch(invalidateStore())
        dispatch(actions.clearUser())
        LocalStorageController.clearUserId().clearToken()
    }
}

export function loginAction(username: string, password: string) {
    return (dispatch: Dispatch<any>) => {
        api.login(username, password).then(({ userId, token }) => {
            dispatch(actions.setUserId(userId, token))
            LocalStorageController.setUserId(userId)
            LocalStorageController.setToken(token)
            dispatch(initAction())
        }).catch(err => dispatch(actions.setError(err)))
    }
}

export function initAction() {
    return (dispatch: Dispatch<any>) => {
        if (LocalStorageController.userId && LocalStorageController.token) {
            dispatch(actions.setLogged(true))
            api.gerProfile().then(profile => {
                dispatch(actions.setUser(profile))
            }).catch(err => dispatch(actions.setError(err)))
        }
        else
            dispatch(actions.setLogged(false))
    }
}
export function updateFitter(fitter: Partial<UserType>) {
    return (dispatch: Dispatch<any>) => {
        if (LocalStorageController.userId && LocalStorageController.token) {
            api.patchSelfFitter(fitter).then(() => {
                dispatch(initAction())
            }).catch(err => dispatch(actions.setError(err)))
        }
        else
            dispatch(actions.setLogged(false))
    }
}