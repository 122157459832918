import { Fab, fade, makeStyles, Table, TableBody, TableCell, TableContainer, Theme } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import GenericTableHeader from '../components/generics/GenericTableHeader';
import GenericTableRow from '../components/generics/GenericTableRow';
import SummaryHeader from '../components/summary/SummaryHeader';
import useSortTable from '../hooks/useSortTable';
import { PLAN_ENUM } from '../models/Fit';
import { SummaryType } from '../models/Summary';
import { api } from '../services';
import { ReducerType } from '../store';
import { fetchSummaryAction } from '../store/actions/summaryActions';
import { currentMonth, currentWeek, currentYear } from '../utils/date';
import clsx from 'clsx'
const SummaryContainer: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const week = currentWeek()
  const month = currentMonth()
  const year = currentYear()
  const weekSummary = useSelector((state: ReducerType) => state.summary[`end=${week.endDate}&start=${week.startDate}`])
  const monthSummary = useSelector((state: ReducerType) => state.summary[`end=${month.endDate}&start=${month.startDate}`])
  const yearSummary = useSelector((state: ReducerType) => state.summary[`end=${year.endDate}&start=${year.startDate}`])

  useEffect(() => {
    if (week && month && year) {
      dispatch(fetchSummaryAction(`end=${week.endDate}&start=${week.startDate}`))
      dispatch(fetchSummaryAction(`end=${month.endDate}&start=${month.startDate}`))
      dispatch(fetchSummaryAction(`end=${year.endDate}&start=${year.startDate}`))
    }
  }, [dispatch, week, month, year])
  
  const [selectedSummary, setSelectedSummary] = useState<SummaryType & {index: number} | null>(null)

  const fitMaped = React.useMemo(() => {
    return !selectedSummary ? [] : selectedSummary.fits.map(fit => ({
    ...fit,
    username: fit.user ? fit.user.username : ""
  }))}, [selectedSummary])

  const { sortedData, handleRequestSort, order, orderBy } = useSortTable(fitMaped, 'id')

  const handleSelectSummary = (summary: SummaryType, index: number) => {
    setSelectedSummary({...summary, index})
  }
  
  useEffect(() => {
    if (weekSummary) {
      setSelectedSummary({...weekSummary, index: 1})
    }
  }, [weekSummary])

  const handleExportReport = () => {
    if (selectedSummary) {
      switch (selectedSummary.index) {
        case 1:
          return api.exportSummary(`end=${week.endDate}&start=${week.startDate}`)
        case 2:
          return api.exportSummary(`end=${year.endDate}&start=${year.startDate}`)
        case 3:
          return api.exportSummary(`end=${month.endDate}&start=${month.startDate}`)
        
        default:
          return
      }
    }
  }

  return (
    <div>
    <SummaryHeader 
      weekSummary={weekSummary}
      monthSummary={monthSummary}
      yearSummary={yearSummary}
      selected={selectedSummary ? selectedSummary.index : null}
      onClickSummary={handleSelectSummary}
    />
     <TableContainer >
        <Table size='small' >
          <GenericTableHeader
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={
              [
                {
                  id: 'id',
                  label: 'Id',
                  numeric: true,
                }, 
                {
                  id: 'type',
                  label: 'Plan',
                  align: "center"
                },
                {
                  id: 'amount',
                  label: 'Amount',
                  numeric: true
                },
                {
                  id: 'username',
                  label: 'Username',
                  align: "center"
                }
              ]
            }
        />
          <TableBody>
            {sortedData.map((fit, index) => (
              <GenericTableRow key={index}>
                <TableCell align="right" >{fit.id}</TableCell>
                <TableCell className={clsx([classes.planCell, fit.type === PLAN_ENUM.PRO ? classes.proCell : classes.quickCell])} align="center" >{fit.type}</TableCell>
                <TableCell align="right">
                    {fit.amount}$
                </TableCell>
                <TableCell align="center">
                    {fit.user? fit.user.username : ""}
                </TableCell>
              </GenericTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Fab color="primary" aria-label="add" className={classes.fab} onClick={handleExportReport}>
        <GetAppIcon />
      </Fab>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
    planCell: {
      color: 'black'
    },
    fab: {
      margin: 0,
      top: 'auto',
      right: 20,
      bottom: 20,
      left: 'auto',
      position: 'fixed',
    },
    quickCell: {
        backgroundColor: fade(theme.palette.secondary.light, 0.4),
    },
    proCell: {
        backgroundColor: fade(theme.palette.primary.light, 0.4),
    }
  }))
 
export default SummaryContainer;