import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { routes } from '../routes'

export default function useRouteMapName(){
    const location = useLocation()

    const routeName  = useMemo(() => {
      const result = routes.find(r => {
        var locationPathname = location.pathname.split('/')
        var routePathname = r.path().split('/')
        
        if (locationPathname.length === routePathname.length) {
          if (locationPathname.join() === routePathname.join()) {
            return true
          } else {
            routePathname.pop()
            locationPathname.pop()
            return routePathname.join() !== "" && locationPathname.join() === routePathname.join()
          }
        }
        return false
      })
      return result ? result.name : false
    }, [location.pathname])
    
    return routeName
}