import React, { useMemo } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 20
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    buttonsContainer: {
      position: "absolute",
      right: 40
    }
  }),
);

export type StepOptionsType = {
  title: string,
  component: React.ReactNode
}

export type StepperProps = {
  canFinish: boolean | null,
  options: StepOptionsType[],
  onFinish: () => void,
  defaultStep?:number
}

export default function GenericStepper(props: StepperProps) {
  const {options, canFinish, onFinish} = props
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(props.defaultStep ?? 0);

  const steps = useMemo(() => {
    return options.map(option => option.title)
  }, [options])

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      onFinish()
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const disableButton = useMemo(() => {
    return activeStep === steps.length - 1 && !canFinish
  }, [activeStep, canFinish, steps.length])

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel style={{background: 'none'}}>
        {steps.map((label, index) => (
          <Step key={index} >
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
        <div className={classes.buttonsContainer}>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            className={classes.backButton}
          >
            Back
          </Button>
          <Button variant="contained" color="primary" onClick={handleNext} disabled={disableButton}>
            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
          </Button>
        </div>
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>All steps completed</Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
            {options[activeStep].component}
          </div>
        )}
      </div>
    </div>
  );
}