import React from 'react'
import { useDispatch } from 'react-redux'
import LoginView from '../Views/LoginView'
import { loginAction } from '../store/actions/sessionActions'
import { ENUM_ROUTES, FitrRouteComponentProps } from '../routes'

function LoginContainer(props: FitrRouteComponentProps<ENUM_ROUTES.LOGIN>) {
  const dispatch = useDispatch()
  const onSubmit = React.useCallback((username: string, pass: string) => {
    dispatch(loginAction(username, pass))
  }, [dispatch])
  return <LoginView onSubmit={onSubmit}  {...props}/>
}

export default LoginContainer