import { UserType } from "../../models/User"
import { SessionActions } from "../actions/sessionActions"

export type SessionReducerType = {
    user: UserType | null
    userId: number | null,
    error: Error | null
    isLogged: boolean | null
}

const initState: SessionReducerType = {
    user: null,
    error: null,
    userId: null,
    isLogged: null
}
export default function session(store: SessionReducerType = initState, action: SessionActions): SessionReducerType {
    switch (action.type) {
        case 'CLEAR_USER':
            return {
                ...store,
                user: null,
                userId: null,
                error: null,
                isLogged: false
            }
        case 'SET_USER_ID':
            return {
                ...store,
                userId: action.userId,
                isLogged: true,
                error: null
            }

        case 'SET_USER':
            return {
                ...store,
                user: action.user,
                isLogged: true,
                error: null
            }
        case 'ERROR_USER':
            return {
                ...store,
                userId: null,
                isLogged: false,
                error: action.error

            }
        case 'LOGGED':
            return {
                ...store,
                isLogged: action.isLogged
            }

        default:
            return store
    }
}