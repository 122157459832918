import React, { useState } from 'react'
import SignUpView from '../Views/SignUpView'
import { SignUp } from '../models/SignUp'
import { register } from '../services/api'
import { ENUM_ROUTES, FitrRouteComponentProps, loginRoute } from '../routes'


function SignUpContainer(props : FitrRouteComponentProps<ENUM_ROUTES.SIGN_UP>) {

  const [error, setError] = useState<boolean>(false)

  const onSubmit = React.useCallback((signUpData: SignUp) => {
    register(signUpData).then(res => {
      setError(false)
      props.history.push(loginRoute.path())
    }).catch(err => setError(true))
  }, [props.history])

  return <SignUpView onSubmit={onSubmit} error={error}/>
}

export default SignUpContainer