const paths = {
  mainPath: () => '/',
  fitPath: () => '/fit',
  fitByIdPath: (id: string) => `/fit/${id}`,
  manageFitsPath: (type: string) => `/manageFits/${type}`,
  calendar: () => '/calendar',
  getFitter: () => '/fitter',
  getSummary: () => '/summary',
  getLogin: () => '/login',
  getSignUp: () => '/signUp'
}
export default paths
