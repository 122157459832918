import React from 'react'
import ModificationDialog ,{ ModificationOptions } from '../../components/analysis/modifications/ModificationDialog';
import { ModificationType } from '../../models/Analysis';
import { api } from '../../services';
import { fitModel } from '../../store';

type CommonProps = {
  data: ModificationOptions,
  onClose: () => void,
}

export type ModificationDialogContainerProps = CommonProps
 
const ModificationDialogContainer: React.FC<ModificationDialogContainerProps> = (props) => {
  const {data, onClose} = props
  const {setInvalidate: invalidateFit} = fitModel.uses.useFetchById({}, data.fit.id)

  const handlePostModification = async (modification: Partial<ModificationType>) => {
    var analysis = data.fit.analysis
    if (!analysis) {
      analysis = await api.postAnalysis(data.fit.id)
    } 
    if (analysis) {
      api.postModification(analysis.id, modification).then(res => {
        invalidateFit(data.fit.id)
        onClose()
      })
    } else {
      console.error("Error creating analysis")
    }
  }

  const handlePutModification = (modification: Partial<ModificationType>) => {
    
    if (data.isEdit && data.modification.id) {
      const modificationAux: ModificationType = {
        ...data.modification,
        ...modification
      }
     
      api.putModification(data.modification.id, modificationAux).then(res => {
        invalidateFit(data.fit.id)
        onClose()
      })
     console.log("PUT DONE!")
    }
  }

  return (
    <ModificationDialog 
      open
      data={data}
      onClose={onClose}
      onSubmit={props.data.isEdit? handlePutModification : handlePostModification} 
    />
  );
}
 
export default ModificationDialogContainer;