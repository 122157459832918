import { AppointmentTooltip } from '@devexpress/dx-react-scheduler-material-ui';
import { Link, Typography } from '@material-ui/core';
import React from 'react'
import { useHistory } from 'react-router-dom';
import { FitrAppointmentModel } from '../../models/Session';
import { paths } from '../../routes';
import GenericButton from '../generics/GenericButton';

function FitrAppointmentContent(props: AppointmentTooltip.ContentProps & { appointmentData?: FitrAppointmentModel }) {

  const history = useHistory()
  
  const handleGoToFit = React.useCallback(() => {
    if (props.appointmentData && props.appointmentData.fitId)
      history.push(paths.fitByIdPath(props.appointmentData.fitId.toString()))
  }, [history,props.appointmentData])

  return <AppointmentTooltip.Content {...props} >
    {props.appointmentData && props.appointmentData.booked && <div>
      <table>
        <tr>
          <td>
            <Typography>Zoom link</Typography>
          </td>
          <td>
            <Link href={props.appointmentData.meetingUrl}>{props.appointmentData.meetingUrl}</Link>
          </td>
        </tr>
        <tr>
          <td>
            <Typography>Zoom Password</Typography>
          </td>
          <td>
            <Typography>{props.appointmentData.meetingPassword}</Typography>
          </td>
        </tr>
      </table>
      <GenericButton title='Go to Fit' onClick={ handleGoToFit} >Go to Fit</GenericButton>
    </div>}
  </AppointmentTooltip.Content>
}
export default FitrAppointmentContent