import { UserType } from "../models/User";
import axios from "axios";
import LocalStorageController from "../utils/LocalStorageController";
import { FitType, PLAN_ENUM } from "../models/Fit";
import { FormSessionType, SessionType } from "../models/Session";
import {
  AnalysisType,
  DiagnosticType,
  ModificationType,
} from "../models/Analysis";
import { AdjustmentType } from "../models/Adjustment";
import { TagType } from "../models/Tag";
import { FitterType } from "../models/Fitter";
import { BikeType } from "../models/Bike";
import { SignUp } from "../models/SignUp";
import { SummaryType } from "../models/Summary";
import dayjs from "dayjs";
console.log(process.env.PUBLIC_URL);
const axiosToken = axios.create({
  baseURL: process.env.PUBLIC_URL || "/",
  headers: {
    Authorization: LocalStorageController.token,
  },
});
const axiosLogin = axios.create({
  baseURL: process.env.PUBLIC_URL || "/",
});
axiosToken.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    // TODO : Remove TOKEN
    if (err) return err;
  }
);
type LoginReturnType = {
  token: string;
  username: string;
  firstName: string;
  secondName: string;
  userId: number;
};
export function login(
  username: string,
  password: string
): Promise<LoginReturnType> {
  const body: { username: string; password: string } = { username, password };
  return axiosLogin.post<LoginReturnType>("/api/v1/login", body).then((res) => {
    axiosToken.defaults.headers = {
      Authorization: res.data.token,
    };
    return res.data;
  });
}
export function register(body: SignUp): Promise<any> {
  return axiosLogin.post("/api/v1/fitter/register", body);
}
export function logout() {
  return fetch("/api/logout");
}

export function recoverPassword(username: string) {
  return axiosLogin.post(`/api/v1/reset-password`, { username });
}

export function gerProfile(): Promise<UserType> {
  return axiosToken.get("/api/v1/user/self").then((res) => res.data);
}
export function patchSelfFitter(fitter: Partial<FitterType>) {
  return axiosToken.patch("/api/v1/fitter", fitter).then((res) => res.data);
}
export function postAvatar(form: FormData) {
  return axiosToken.post(
    "/api/v1/user/avatar/" + LocalStorageController.userId,
    form,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
}
// FITS
export function getFits(): Promise<FitType[]> {
  return axiosToken.get("/api/v1/fit").then((res) => res.data);
}
export function getAdminFits(): Promise<FitType[]> {
  return axiosToken
    .get(`/api/v1/admin/fit?finished=false&type=${PLAN_ENUM.QUICK}`)
    .then((res) => res.data);
}
export function getAdminProFits(): Promise<FitType[]> {
  return axiosToken
    .get(`/api/v1/admin/fit?finished=false&type=${PLAN_ENUM.PRO}`)
    .then((res) => res.data);
}
export function getFitById(id: number): Promise<FitType> {
  return axiosToken.get(`/api/v1/fit/${id}`).then((res) => res.data);
}
export function patchFitWithFitterId(fit: number, fitterId: number) {
  return axiosToken.patch(`/api/v1/admin/fit/${fit}`, { fitterId });
}

// ANALYSIS
export function postAnalysis(fitId: number): Promise<AnalysisType> {
  return axiosToken
    .post(`/api/v1/analysis/fit/${fitId}`, {})
    .then((res) => res.data);
}
export function patchAnalysisWithIntroduction(
  analysisId: number,
  fitterIntroduction: string
): Promise<AnalysisType> {
  return axiosToken
    .patch(`/api/v1/analysis/${analysisId}`, { fitterIntroduction })
    .then((res) => res.data);
}
export function patchFinishAnalysis(analysisId: number): Promise<AnalysisType> {
  return axiosToken
    .patch(`/api/v1/analysis/${analysisId}/finish/${true}`, {})
    .then((res) => res.data);
}
export function postDiagnostic(
  analysisId: number,
  mediaId: number,
  isVideo: boolean,
  diagnostic: DiagnosticType
) {
  const mediaType = isVideo ? "video" : "image";
  return axiosToken
    .post(
      `/api/v1/diagnostic/analysis/${analysisId}/${mediaType}/${mediaId}`,
      diagnostic
    )
    .then((res) => res.data);
}
export function putDiagnostic(id: number, diagnostic: DiagnosticType) {
  return axiosToken
    .put(`/api/v1/diagnostic/${id}`, diagnostic)
    .then((res) => res.data);
}
export function getModificationsByAnalysis(
  analysisId: number
): Promise<ModificationType[]> {
  return axiosToken
    .get(`/api/v1/modification/analysis/${analysisId}`)
    .then((res) => res.data);
}
export function postModification(
  analysisId: number,
  modification: Partial<ModificationType>
): Promise<ModificationType> {
  return axiosToken
    .post(`/api/v1/modification/analysis/${analysisId}`, modification)
    .then((res) => res.data);
}
export function putModification(
  modificationId: number,
  modification: Partial<ModificationType>
): Promise<ModificationType> {
  return axiosToken
    .put(`/api/v1/modification/${modificationId}`, modification)
    .then((res) => res.data);
}

//ADJUSTMENTS
export function getAdjustments(): Promise<AdjustmentType[]> {
  return axiosToken.get(`/api/v1/adjustment`).then((res) => res.data);
}

//TAGS
export function getTags(): Promise<TagType[]> {
  return axiosToken.get(`/api/v1/adjustment/tag`).then((res) => res.data);
}

//BIKES
export function getBikes(): Promise<BikeType[]> {
  return axiosToken.get(`/api/v1/bike`).then((res) => res.data);
}
export function getBikeById(bikeId: number): Promise<BikeType> {
  return axiosToken.get(`/api/v1/bike/${bikeId}`).then((res) => res.data);
}

// USER
export function getAllUsers(): Promise<UserType[]> {
  return axiosToken.get(`/api/v1/user`).then((res) => res.data);
}
export function getUserById(id: number): Promise<UserType> {
  return axiosToken.get(`/api/v1/user/${id}`).then((res) => res.data);
}
//Calendar
export function getAllSesions(): Promise<SessionType[]> {
  return axiosToken.get(`/api/v1/fitter/session`).then((res) => res.data);
}
export function createSession(session: FormSessionType) {
  return axiosToken.post(`/api/v1/fitter/session`, {
    startDate: session.startDate.getTime(),
    endDate: session.endDate.getTime(),
    localStartDateFitter: dayjs(session.startDate).format('YYYY-MM-DDTHH:mm:ss')
  });
}
export function putSession(id: number, session: FormSessionType) {
  return axiosToken.put(`/api/v1/fitter/session/${id}`, {
    startDate: session.startDate.getTime(),
    endDate: session.endDate.getTime(),
    localStartDateFitter: dayjs(session.startDate).format('YYYY-MM-DDTHH:mm:ss')
  });
}
export function deleteSession(id: number) {
  return axiosToken.delete(`/api/v1/fitter/session/${id}`);
}

//Fitters
export function getFitters(): Promise<FitterType[]> {
  return axiosToken.get(`/api/v1/admin/fitters`).then((data) => data.data);
}
export function getFitterById(id: number): Promise<FitterType> {
  return axiosToken.get(`/api/v1/admin/fitter/${id}`).then((data) => data.data);
}
export function patchFitter(id: number, obj: Partial<FitterType>) {
  return axiosToken.patch(`/api/v1/admin/fitter/${id}`, obj);
}

//SUMMARY
export function getSummary(queryString: string): Promise<SummaryType> {
  return axiosToken
    .get(`/api/v1/report/performance-report?${queryString}`)
    .then((res) => res.data);
}

export function exportSummary(queryString: string) {
  var xhr = new XMLHttpRequest();
  xhr.open("GET", `/api/v1/report/performance-report/pdf?${queryString}`, true);
  xhr.setRequestHeader("Authorization", LocalStorageController.token!);
  xhr.responseType = "arraybuffer";
  xhr.onload = function (e) {
    if (this.status === 200) {
      var blob = new Blob([this.response], { type: "application/pdf" });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `Report_${queryString}.pdf`;
      link.click();
    }
  };
  xhr.send();
}
