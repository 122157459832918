import { makeStyles, Theme } from '@material-ui/core';
import React from 'react'
import { SummaryType } from '../../models/Summary';
import SummaryCard from './SummaryCard';

export interface SummaryHeaderProps {
  weekSummary: SummaryType | null,
  yearSummary: SummaryType | null,
  monthSummary: SummaryType | null,
  selected: number | null,
  onClickSummary: (summary: SummaryType, index: number) => void
}
 
const SummaryHeader: React.FC<SummaryHeaderProps> = (props) => {
  const {weekSummary, yearSummary, monthSummary, selected, onClickSummary} = props
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {weekSummary && <SummaryCard title={"week"} summary={weekSummary} onClick={() => onClickSummary(weekSummary, 1)} selected={selected === 1}/> }
      {monthSummary && <SummaryCard title={"month"} summary={monthSummary} onClick={() => onClickSummary(monthSummary, 3)} selected={selected === 3}/>}
      {yearSummary && <SummaryCard title={"year"} summary={yearSummary} onClick={() => onClickSummary(yearSummary, 2)} selected={selected === 2}/> }
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  }
}))
 
export default SummaryHeader;