import React from "react"
import { Button, Card, CardActions, CardContent, CardMedia, Collapse, createStyles, IconButton, makeStyles, Theme, Typography } from "@material-ui/core"
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ModificationType } from "../../../models/Analysis";
import { Edit } from "@material-ui/icons";
import { FitType } from "../../../models/Fit";

type Props = {
  fit: FitType,
  modification: ModificationType,
  className?: string | string[],
  onEdit: (options: ModificationType) => void
}

function ModificationsCardComponent(props: Props) {
  const {fit, modification, className, onEdit} = props
  const classes = useStyles()
  
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleOpenModificationDialog = () => {
    onEdit(modification)
  }

  return (
    <Card className={clsx([classes.root, className])}>
      <CardMedia
        className={classes.media}
        image={modification.adjustment.media}
        component='img'
      />
      <CardContent className={classes.cardContent}>
        <Typography className={classes.cardTitle}>{modification.adjustment.title}</Typography>
      </CardContent>
      <CardActions className={classes.cardActions} disableSpacing>
        {!fit.analysis?.finished &&
          <Button
            onClick={handleOpenModificationDialog}
            variant="outlined"
            startIcon={<Edit />}
        >
          EDIT
        </Button>
        }
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {modification.steps.map((step, index) => (
            <Typography paragraph key={index}>
              {`${index + 1}. ${step}`}
            </Typography>
          ))}
        </CardContent>
      </Collapse>
    </Card>
  );
}
const useStyles = makeStyles((theme : Theme) => createStyles({
  root: {
    width: 200,
    marginRight: 20,
    marginBottom: 20,
    [theme.breakpoints.down('md')]: {
      marginRight: 10,
      marginBottom: 10,
    }
  },
  media: {
    overflowY: 'hidden',
    objectFit: 'cover',
    height: 125,
  },
  cardTitle: {
    fontSize: 14,
    textTransform: 'uppercase',
    padding: 0,
  },
  cardContent: {
    padding: 10,
    paddingBottom: 0,
  },
  cardActions: {
    padding: 10,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}))

export default ModificationsCardComponent