import { Dispatch } from "react";
import { ReducerType } from "..";
import { api } from "../../services";
import { FETCH_TAGS } from "../actionTypes/tagActionTypes";

export function fetchTagsAction() {
    return (dispatch: Dispatch<any>,  getState: () => ReducerType) => {
        if (getState().tags.length === 0) {
          api.getTags().then(res => {
            dispatch(fetchTags(res))
          })
        }
    }
}

const fetchTags = (tags: string[]) => ({
  type: FETCH_TAGS,
  payload: tags
})