import React from 'react'
import DiagnosticDialog, { DiagnosticOptions } from '../../components/analysis/DiagnosticDialog';
import { DiagnosticType } from '../../models/Analysis';
import { api } from '../../services';
import { fitModel } from '../../store';


type CommonProps = {
  data: DiagnosticOptions,
  onClose: () => void,
}

export type DiagnosticDialogContainerProps = CommonProps
 
const DiagnosticDialogContainer: React.FC<DiagnosticDialogContainerProps> = (props) => {
  const {data, onClose} = props
  const {setInvalidate: invalidateFit} = fitModel.uses.useFetchById({}, data.fit.id)

  const handlePostDiagnostic = async (diagnostic: DiagnosticType) => {
    var analysis = data.fit.analysis
    if (!analysis) {
      analysis = await api.postAnalysis(data.fit.id)
    } 
    if (analysis) {
      api.postDiagnostic(analysis.id, props.data.media.id, !!props.data.isVideo, diagnostic).then(res => {
        invalidateFit(data.fit.id)
        onClose()
      })
    } else {
      console.error("Error creating analysis")
    }
  }

  const handlePutDiagnostic = (diagnostic: DiagnosticType) => {
    
    if (data.isEdit && data.diagnostic.id) {
      const diagnosticAux: DiagnosticType = {
        ...data.diagnostic,
        ...diagnostic
      }
      api.putDiagnostic(data.diagnostic.id, diagnosticAux).then(res => {
        invalidateFit(data.fit.id)
        onClose()
      })
    }
  }

  return (
    <DiagnosticDialog open onClose={onClose} data={data} onSubmit={props.data.isEdit? handlePutDiagnostic : handlePostDiagnostic} />
  );
}
 
export default DiagnosticDialogContainer;