import React from 'react';
import clsx from 'clsx';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer, { DrawerProps } from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import PeopleIcon from '@material-ui/icons/People';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { Omit } from '@material-ui/types';
import useRouteName from '../hooks/useRouteName';
import { calendarRoute, fitRoute, fitterRoute, summaryRoute, manageFitsRoute, manageProFitsRoute } from '../routes';
import { Link } from 'react-router-dom';
import { BorderColorOutlined, DirectionsBike } from '@material-ui/icons';
import fitrIcon from '../assets/icons/fitr-icon-white.png'
import { Typography } from '@material-ui/core';
import { useSelectorSession } from '../hooks/useInitApp';
import { ROL } from '../models/User';

const categories = [
  {
    id: 'Management',
    children: [
      { id: 'Alta / Baja / Modificacion', icon: <PeopleIcon />, route: fitterRoute },
      { id: 'Quick fits management', icon: <BorderColorOutlined />, route:  manageFitsRoute },
      { id: 'Pro fits management', icon: <BorderColorOutlined />, route:  manageProFitsRoute },
      //{ id: 'Visible / no visible', icon: <DnsRoundedIcon /> },
      //{ id: 'Georgafia / Region', icon: <PermMediaOutlinedIcon /> },
    ],
    rols: [ROL.ROLE_ADMIN]
  },
  {
    id: 'Fits Management',
    children: [
      { id: 'Your fits', icon: <DirectionsBike />, route: fitRoute },
    ],
    rols: [ROL.ROLE_FITTER]
  },
  {
    id: 'Calendar Management',
    children: [
      //{ id: 'Global calendar', icon: <PeopleIcon /> },
      { id: 'Appointments', icon: <CalendarIcon />, route: calendarRoute },
    ],
    rols: [ROL.ROLE_FITTER]
  },
  {
    id: 'PERFORMANCE summary',
    children: [
      { id: 'Summary', icon: <AssessmentIcon />, route: summaryRoute },
    ],
    rols: [ROL.ROLE_ADMIN]
  },
];

const styles = (theme: Theme) => {
  return createStyles({
    categoryHeader: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    categoryHeaderPrimary: {
      color: theme.palette.common.white,
    },
    item: {
      paddingTop: 1,
      paddingBottom: 1,
      color: 'rgba(255, 255, 255, 0.7)',
      '&:hover,&:focus': {
        backgroundColor: 'rgba(255, 255, 255, 0.08)',
      },
    },
    itemCategory: {
      backgroundColor: '#232f3e',
      boxShadow: '0 -1px 0 #404854 inset',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
    firebase: {
      fontSize: 24,
      color: theme.palette.common.white,
    },
    itemActiveItem: {
      color: '#4fc3f7',
    },
    itemPrimary: {
      fontSize: 'inherit'
    },
    itemIcon: {
      minWidth: 'auto',
      marginRight: theme.spacing(2),
    },
    fitrIcon: {
      height: 40
    },
    fitrTitle: {
      marginLeft: 10
    },
    divider: {
      marginTop: theme.spacing(2),
    },
  })
}

export interface NavigatorProps extends Omit<DrawerProps, 'classes'>, WithStyles<typeof styles> { }

function Navigator(props: NavigatorProps) {
  const { classes, ...other } = props;
  const { user: fitter } = useSelectorSession()
  const routeName = useRouteName()
  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
          <img className={classes.fitrIcon} src={fitrIcon} alt='fitr' />
          <Typography className={classes.fitrTitle} variant='h4'>FITR</Typography>
        </ListItem>
        <ListItem className={clsx(classes.item, classes.itemCategory)}>
          <ListItemIcon className={classes.itemIcon}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
            }}
          >
            Project Overview
          </ListItemText>
        </ListItem>
        {categories.map(({ id, rols, children }) => (
          rols && !fitter?.roles.some(rol => rols.indexOf(rol) >= 0 ) ? <></> :
          <React.Fragment key={id}>
            <ListItem className={classes.categoryHeader} >
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, route }) => (
              <Link to={route.path()} key={childId} style={{textDecoration: "none"}}>
                <ListItem
                  key={childId}
                  button
                  className={clsx(classes.item, routeName && route.name === routeName.name && classes.itemActiveItem)}
                >
                  <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                  <ListItemText
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  >
                    {childId}
                  </ListItemText>
                </ListItem>
              </Link>
            ))}
              <Divider className={classes.divider} key={id}/>
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}

export default withStyles(styles)(Navigator);
