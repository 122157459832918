import React, { FormEvent } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { ENUM_ROUTES, FitrRouteComponentProps, signUpRoute } from '../routes';

import fitrImage from '../assets/FITRx1024.png'
import ForgotPasswordDialog from '../components/login/ForgotPasswordDialog';
type Props = {
    classes: any,
    invalid?: boolean,
    onSubmit: (user: string, password: string) => void,
    icon?: JSX.Element
}

class LoginUI extends React.Component<Props & FitrRouteComponentProps<ENUM_ROUTES.LOGIN>> {
    state = {
        modified: false,
        forgotPasswordOpen: false
    }

    private user = React.createRef<HTMLInputElement>()
    pass = React.createRef<HTMLInputElement>()
    remember = React.createRef<HTMLInputElement>()

    onSubmit(ev: FormEvent<HTMLFormElement>) {
        ev.preventDefault();
        const username = this.user.current!.value
        const password = this.pass.current!.value
        const remember = this.remember.current!.checked
        if (remember)
            localStorage.setItem('username', username)
        else
            localStorage.setItem('username', '')
        this.props.onSubmit(username, password);
    }

    handleSignOut = () => {
        this.props.history.push(signUpRoute.path())
    }

    handleClickOpenForgotPassowrd = () => {
        this.setState({ forgotPasswordOpen: true })
    }
    handleClickCloseForgotPassowrd = () => {
        this.setState({ forgotPasswordOpen: false })
    }

    render() {
        const user = localStorage.getItem('username') ? localStorage.getItem('username')! : undefined
        return (
            <React.Fragment>
                <ForgotPasswordDialog open={this.state.forgotPasswordOpen} onClose={ this.handleClickCloseForgotPassowrd }/>
                <CssBaseline />
                <main className={this.props.classes.layout}>
                    <Paper className={this.props.classes.paper}>
                        <Avatar className={this.props.classes.avatar} src={fitrImage}/>
                        <Typography variant='h5'>Log in</Typography>
                        <form className={this.props.classes.form} onSubmit={this.onSubmit.bind(this)}>
                            <FormControl margin='normal' required fullWidth>
                                <InputLabel htmlFor='username'>User name</InputLabel>
                                <Input inputRef={this.user}
                                    error={this.props.invalid && !this.state.modified}
                                    onChange={ev => this.setState({ modified: true })}
                                    name='username'
                                    autoComplete='username'
                                    defaultValue={user}
                                    autoFocus={user === undefined} />
                            </FormControl>
                            <FormControl margin='normal' required fullWidth>
                                <InputLabel htmlFor='password'>Password</InputLabel>
                                <Input
                                    error={this.props.invalid && !this.state.modified}
                                    onChange={ev => this.setState({ modified: true })}
                                    name='password'
                                    type='password'
                                    inputRef={this.pass}
                                    autoFocus={user !== undefined}
                                    autoComplete='current-password'
                                />
                            </FormControl>
                            <FormControlLabel
                                control={<Checkbox value='remember' inputRef={this.remember} defaultChecked={user !== undefined} color='primary' />}
                                label='Remember me'
                            />
                            {this.props.invalid && <Typography color='secondary' variant='subtitle2'>Invalid username or password</Typography>}
                            <Button
                                type='submit'
                                fullWidth
                                variant='contained'
                                color='primary'
                                className={this.props.classes.submit} >
                                Log in
                            </Button>
                            <Button
                                fullWidth
                                variant='contained'
                                color='secondary'
                                className={this.props.classes.submit}
                                onClick={this.handleSignOut}
                            >
                                Sign up
                            </Button>
                        </form>

                        <Typography className={this.props.classes.textButton} onClick={ this.handleClickOpenForgotPassowrd}>Forgot password?</Typography>
                    </Paper>
                </main>
            </React.Fragment>
        );
    }
}
export default withStyles(theme => ({
    layout: {
        width: 'auto',
        display: 'block', // Fix IE11 issue.
        marginLeft: theme.spacing() * 3,
        marginRight: theme.spacing() * 3,
        [theme.breakpoints.up(400 + theme.spacing() * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing() * 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing() * 2}px ${theme.spacing() * 3}px ${theme.spacing() * 3}px`,
    },
    avatar: {
        margin: theme.spacing(),
        backgroundColor: theme.palette.primary.main,
    },
    avatarIcon: {
        color: theme.palette.secondary.dark
    },
    form: {
        width: '100%', // Fix IE11 issue.
        marginTop: theme.spacing(),
    },
    submit: {
        marginTop: theme.spacing() * 3,
    },
    textButton: {
        margin: theme.spacing(),
        cursor: 'pointer'
    }
}))(LoginUI);