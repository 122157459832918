import { createStyles, Input, makeStyles, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { api } from '../../services'
import GenericButton from '../generics/GenericButton'
import GenericDialog from '../generics/GenericDialog'

export interface ForgotPasswordDialogProps {
  open: boolean,
  onClose: () => void
}
 
const ForgotPassword: React.FC<ForgotPasswordDialogProps> = (props) => {
  const classes = styles()
  const { open, onClose } = props

  const email = React.createRef<HTMLInputElement>()
  const [checkMailMessage, setCheckMailMessage] = useState<string | null>(null)
  const [errorMail, setErrorMail] = useState<string | null>(null)

  const handleCloseDialog = () => {
    onClose()
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (email.current?.value) {
      api.recoverPassword(email.current.value).then(() => {
        setCheckMailMessage("Check your email!")
        setErrorMail(null)
        setTimeout(() => { 
          onClose()
        }, 2000)
      }).catch(e => {
        setCheckMailMessage(null)
        setErrorMail("Mail not found")
      })
    }
  }

  return (
    <GenericDialog open={open} title="Forgot password" onClose={handleCloseDialog}>
      <div className={ classes.container}>
        <Typography variant="subtitle1">
          If you have forgotten your password, you will receive a new one in your email
        </Typography>
        <form className={ classes.form} onSubmit={handleSubmit}>
          <Input type="email" placeholder="Email" inputRef={email}/>
          <GenericButton className={classes.sendButton} type="submit">Send</GenericButton>
          {checkMailMessage &&
            <Typography className={ classes.successfullyMessage } variant="caption">
              {checkMailMessage}
            </Typography>
          }
          {errorMail &&
            <Typography className={ classes.errorMessage } variant="caption">
              {errorMail}
            </Typography>
          }
        </form>
      </div>
    </GenericDialog>
  );
}

const styles = makeStyles(theme => createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: 400,
    padding: theme.spacing()
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  sendButton: {
    marginTop: theme.spacing()
  },
  successfullyMessage: {
    color: 'green'
  },
  errorMessage: {
    color: theme.palette.error.main
  }
}))
 
export default ForgotPassword;