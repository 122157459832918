import React from "react"
import { Card, CardContent, CardMedia, createStyles, makeStyles, Radio, Typography } from "@material-ui/core"
import clsx from 'clsx';
import { AdjustmentType } from "../../../models/Adjustment";

type Props = {
  adjustment: AdjustmentType,
  selected?: boolean,
  onSelect: (adjustment: AdjustmentType) => void
}

function AdjustmentCardComponent(props: Props) {
  const { adjustment, selected, onSelect } = props
  const classes = useStyles()

  return (
    <Card className={clsx([classes.root])} onClick={() => onSelect(adjustment)}>
      <CardMedia
        className={classes.media}
        image={adjustment.media}
        component='img'
      />
      <CardContent className={classes.cardContent}>
        <Typography className={classes.cardTitle} >
          {adjustment.title}
        </Typography>
        <Radio
          className={classes.radioBtn}
          checked={selected}
          onChange={() => onSelect(adjustment)}
          value={adjustment.id}
          name="radio-button-adjustment"
        />
      </CardContent>
    </Card>
  );
}
const useStyles = makeStyles(theme => createStyles({
  root: {
    width: 400,
    marginRight: 30,
    marginBottom: 20,
    transition: 'transform .2s',
    [theme.breakpoints.down('md')]: {
      marginRight: 10,
      marginBottom: 10,
      '&:hover': {
        transform: 'scale(1.1)',
        zIndex: 10
      }
    },
    '&:hover': {
      transform: 'scale(1.1)',
      zIndex: 10
    }
  },
  media: {
    //overflowY: 'hidden',
    objectFit: 'contain',
    height: 400,
    zIndex: 10,

  },
  cardTitle: {
    fontSize: 14,
    textTransform: 'uppercase',
    padding: 0,
  },
  cardContent: {
    display: "flex",
    padding: 10,
    paddingBottom: "10px !important",
    flexGrow: 1
  },
  radioBtn: {
    marginLeft: 'auto',
    padding: 0
  }


}))

export default AdjustmentCardComponent