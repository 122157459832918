import React from 'react'
import { Button, makeStyles, Theme, Typography } from '@material-ui/core';
import GenericDialog from './GenericDialog'

export interface ConfirmDialogProps {
  title: string,
  description: string,
  open: boolean,
  onClose: () => void,
  onSubmit: () => void
}
 
const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => {
  const {title, description, open, onClose, onSubmit} = props
  const classes = useStyles()

  return (
    <GenericDialog title={title} open={open} onClose={onClose}>
      <div className={classes.dialogContainer}>
        {description && 
          <Typography>{description}</Typography>
        }
        <div className={classes.buttonsContainer}>
          <Button
            onClick={onClose}
            className={classes.backButton}
          >
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={onSubmit}>
            Finish
          </Button>
            </div>
      </div>
    </GenericDialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogContainer: {
    padding: 20,
    textAlign: "center",
  },
  buttonsContainer: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
}))
 
export default ConfirmDialog;