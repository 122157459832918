import React from 'react';
import Header from './components/Header';
import Router, { NotLoggedRouter } from './routes'
import { Hidden, makeStyles, createStyles } from '@material-ui/core';
import Navigator from './components/Navigator'
import useInitApp from './hooks/useInitApp';

/*
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
*/

function App() {
  //State

  //Hooks
  const { isLogged } = useInitApp()
  const classes = styles()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  if(isLogged === null) return <div></div>
  if (isLogged === false) return <NotLoggedRouter />
  else
    return (<div className={classes.root}>
      <nav className={classes.drawer}>
        <Hidden smUp implementation="js">
          <Navigator
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
        </Hidden>
        <Hidden xsDown implementation="css">
          <Navigator PaperProps={{ style: { width: drawerWidth } }} />
        </Hidden>
      </nav>
      <div className={classes.app}>
        <Header onDrawerToggle={() => { }} />
        <main className={classes.main}>
          <Router />
        </main>
        <footer className={classes.footer}>
          {/* <Copyright /> */}
        </footer>
      </div>
    </div>
    )

}
const drawerWidth = 256;

const styles = makeStyles(theme => createStyles({
  root: {
    display: 'flex',
    minHeight: '100vh',
    backgroundColor: theme.palette.background.default
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    //background: '#eaeff1',
  },
  footer: {
    padding: theme.spacing(2),
    //background: '#eaeff1',
  },
}))

export default App;
