import React from 'react'
import { Switch, Route, Redirect } from "react-router-dom"
//router config
import paths from './paths'
import { ENUM_ROUTES } from './EnumRoutes'
import { RouteParamList, ParamList, FitrRouteComponentProps } from './routeTypes'
//Views
import Main from "../Views/Main"
import FitView from '../Views/FitView'
import FitByIdView from '../Views/FitByIdView'
import FitrCalendarView from '../Views/FitrCalendarView'
import FittersTableView from '../Views/FiitersTableView'
import LoginContainer from '../containers/LoginViewContainer'
import SignUpContainer from '../containers/SignUpContainer'
import SummaryView from '../Views/SummaryView'
import { useSelectorSession } from '../hooks/useInitApp'
import { ROL } from '../models/User'
import ManageFitsView from '../Views/ManageFitsView'
import { PLAN_ENUM } from '../models/Fit'

export type RouteType = {
    path: <T extends []>(...args: T) => string,
    component: React.ComponentType<FitrRouteComponentProps<any>>,
    name: ENUM_ROUTES
}
//Routes Objects
export const mainRoute: RouteType = {
    path: () => paths.mainPath(),
    component: Main,
    name: ENUM_ROUTES.MAIN
}
export const manageFitsRoute: RouteType = {
    path: () => paths.manageFitsPath(PLAN_ENUM.QUICK.toLowerCase()),
    component: () => <ManageFitsView type={PLAN_ENUM.QUICK}/>,
    name: ENUM_ROUTES.MANAGE_QUICK_FITS
}
export const manageProFitsRoute: RouteType = {
    path: () => paths.manageFitsPath(PLAN_ENUM.PRO.toLowerCase()),
    component: () => <ManageFitsView type={PLAN_ENUM.PRO}/>,
    name: ENUM_ROUTES.MANAGE_PRO_FITS
}
export const fitRoute: RouteType = {
    path: () => paths.fitPath(),
    component: FitView,
    name: ENUM_ROUTES.ALL_FIT
}
export const fitIdRoute: RouteType = {
    path: (id: string = ':id') => paths.fitByIdPath(id),
    component: FitByIdView,
    name: ENUM_ROUTES.FIT_BY_ID
}
export const calendarRoute: RouteType = {
    path: () => paths.calendar(),
    component: FitrCalendarView,
    name: ENUM_ROUTES.CALENDAR
}
export const fitterRoute: RouteType = {
    path: () => paths.getFitter(),
    component: FittersTableView,
    name: ENUM_ROUTES.FITTERS
}
export const loginRoute: RouteType = {
    path: () => paths.getLogin(),
    component: LoginContainer,
    name: ENUM_ROUTES.LOGIN
}
export const signUpRoute: RouteType = {
    path: () => paths.getSignUp(),
    component: SignUpContainer,
    name: ENUM_ROUTES.SIGN_UP
}
export const summaryRoute: RouteType = {
    path: () => paths.getSummary(),
    component: SummaryView,
    name: ENUM_ROUTES.SUMMARY
}
export const routes: RouteType[] = [
    mainRoute,
    fitRoute,
    fitIdRoute,
    calendarRoute,
    fitterRoute,
    summaryRoute,
    manageFitsRoute,
    manageProFitsRoute
]
export const routesNotLogged: RouteType[] = [
    loginRoute,
    signUpRoute
]
export default function MainRouter() {
    const { user: fitter } = useSelectorSession()
    if (!fitter) return <div></div>
    return <Switch>
        {routes.map(route => <Route path={route.path()} component={route.component} exact key={route.name} />)}
        <Route path='*' >
            {fitter?.roles.includes(ROL.ROLE_ADMIN) ?
                <Redirect to={paths.getFitter()} />
                :
                <Redirect to={paths.fitPath()} />
            }
        </Route>
    </Switch>
}
export function NotLoggedRouter() {
    return <Switch>
        {routesNotLogged.map(route => <Route path={route.path()} component={route.component} exact key={route.name} />)}
        <Route path='*' >
            <Redirect to={paths.getLogin()} />
        </Route>
    </Switch>
}


export { paths, ENUM_ROUTES }
export type { RouteParamList, ParamList, FitrRouteComponentProps }
