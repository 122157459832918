import { createStyles, makeStyles, TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core";
type Order = 'asc' | 'desc';
interface HeadCell<T> {
  disablePadding?: boolean;
  id: keyof T;
  label: string;
  numeric?: boolean;
  disabledFilter?: boolean,
  align?: "right" | "left" | "center"
}
interface EnhancedTableProps<T> {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell<T>[]
}

function GenericTableHeader<T>(props: EnhancedTableProps<T>) {
  const { order, orderBy, onRequestSort, headCells } = props;

  const createSortHandler = (headCell: HeadCell<T>, property: keyof T) => (event: React.MouseEvent<unknown>) => {
    if (headCell.disabledFilter) return
    onRequestSort(event, property);
  };
  const classes = useStyles()
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id.toString()}
            align={headCell.align ? headCell.align : headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell, headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

export default GenericTableHeader