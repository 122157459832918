import React from 'react'
import { createStore as createStoreRedux, combineReducers, Reducer, ReducersMapObject, compose, applyMiddleware, Middleware } from "redux";
import thunk from 'redux-thunk';
import createReducer, { OptionsSearch, RequestType } from './createReducer'
import { useDispatch, useSelector } from 'react-redux';
const middleWare: any[] = [];
middleWare.push(thunk)
type ReducerObj = { [name: string]: Reducer<any, any> }
type Store = ReducerObj & { api: ReducerObj }
var apiReducers: ReducerObj = {}
/**
 * Function for create a Redux Store. </br>
 * The model directly is included in the combineReducer of general Storage </br>
 * Please use with createStore of this library
 * @param name Name of Store
 * @param id id key of the model
 * @param options fetch function. The model will be the Return type of GET and GET BY ID
 * @param keyExtractor a id for each arguments
 */
export function createState<T, ID extends keyof T, APut, Tput, APatch, TPatch, APost, TPost, AGet, AGetById, ARemove>(
    name: string,
    id: ID,
    options: Partial<OptionsSearch<T, ID, APut, Tput, APatch, TPatch, APost, TPost, AGet, AGetById, ARemove>>,
    keyExtractor: {
        get: (args: AGet) => string,
        getById?: (args: AGetById) => string,
    }) {
    const getSelfStore = (state: Store): any => state.api[name]
    const model = createReducer(name, id, getSelfStore, options, keyExtractor)
    apiReducers[name] = model.reducer
    /**
     * USE FETCH
     * @param args if args is undefined the fetch is not realized
     */
    function useFetch(args: AGet | null) {
        const dispatch = useDispatch()
        const key = args ? keyExtractor.get(args as AGet) : ''
        const data = useSelector<Store, RequestType<T[]> & { reduxListener: any }>((store) => {
            const state = getSelfStore(store)
            const request = model.getter.getRequest(state, key)
            const info = model.getter.getAllInfo(state, key)
            const reduxListener = model.getter.getReduxListener(state, key)
            return {
                ...info,
                result: request,
                reduxListener
            }
        })
        const setInvalidate = React.useCallback((args: AGet) => {
            dispatch(model.action.actionObject.invalidate(args))
        }, [dispatch])
        const setInvalidateAll = React.useCallback(() => {
            dispatch(model.action.actionObject.invalidateAll())
        }, [dispatch])
        const post = React.useCallback<typeof model.action.post>((...args) => {
            return dispatch(model.action.post(...args))
        }, [dispatch])
        const put = React.useCallback<typeof model.action.put>((...args) => {
            return dispatch(model.action.put(...args))
        }, [dispatch])
        const patch = React.useCallback<typeof model.action.patch>((...args) => {
            return dispatch(model.action.patch(...args))
        }, [dispatch])
        const remove = React.useCallback<typeof model.action.remove>((...args) => {
            return dispatch(model.action.remove(...args))
        }, [dispatch])
        React.useEffect(() => {
            if (args !== null)
                dispatch(model.action.fetch(args))
        }, [key, data.invalidate, args, dispatch])
        return {
            ...data,
            setInvalidate,
            setInvalidateAll,
            post, put, patch, remove
        }
    }
    /**
     * 
     * @param args if args is undefined, then the useEffect is waiting for add args. If there are no args, put {} or something similar
     * @param id 
     */
    function useFetchById(args: AGetById | undefined, id: T[ID]) {
        const dispatch = useDispatch()
        const data = useSelector<any, RequestType<T | null>>((store) => {
            const state = getSelfStore(store)
            const request = model.getter.getRequestByID(state, id)
            if (request)
                return request
            return {
                error: null,
                invalidate: false,
                isFetching: false,
                result: null
            }
        })
        const setInvalidate = React.useCallback<typeof model.action.actionObject.invalidateById>((...args) => {
            return dispatch(model.action.actionObject.invalidateById(...args))
        }, [dispatch])
        const setInvalidateAll = React.useCallback(() => {
            return dispatch(model.action.actionObject.invalidateAll())
        }, [dispatch])
        const post = React.useCallback<typeof model.action.post>((...args) => {
            return dispatch(model.action.post(...args))
        }, [dispatch])
        const put = React.useCallback<typeof model.action.put>((...args) => {
            return dispatch(model.action.put(...args))
        }, [dispatch])
        const patch = React.useCallback<typeof model.action.patch>((...args) => {
            return dispatch(model.action.patch(...args))
        }, [dispatch])
        const remove = React.useCallback<typeof model.action.remove>((...args) => {
            return dispatch(model.action.remove(...args))
        }, [dispatch])
        React.useEffect(() => {
            if (args) {
                dispatch(model.action.fetchById(args, id))
            }

        }, [id, data.invalidate, args, dispatch])
        return {
            ...data,
            setInvalidate,
            setInvalidateAll,
            post, put, patch, remove
        }
    }
    return {
        ...model,
        getter: {
            isFetching: (store: Store, args: AGet) => model.getter.isFetching(getSelfStore(store), keyExtractor.get(args)),
            isFetchingById: (store: Store, id: T[ID]) => model.getter.isFetchingById(getSelfStore(store), id),
            isInvalidate: (store: Store, args: AGet) => model.getter.isInvalidate(getSelfStore(store), keyExtractor.get(args)),
            isInvalidateById: (store: Store, id: T[ID]) => model.getter.isInvalidateById(getSelfStore(store), id),
            getRequest: (store: Store, args: AGet) => model.getter.getRequest(getSelfStore(store), keyExtractor.get(args)),
            getAllInfo: (store: Store, args: AGet) => model.getter.getAllInfo(getSelfStore(store), keyExtractor.get(args)),
        },
        uses: {
            useFetch,
            useFetchById
        }
    }
}
type ReducerMiddleware = (reducer: Reducer<any>) => Reducer<any>
/**
 * Create Store for automatically configured the reducer create y createState
 * @param storesNews normal reducer for native createStore
 * @param middlewares set your middleware. redux-thunk is aready in use
 */
export function createStore<S>(storesNews: ReducersMapObject<S, any>, middlewares: Middleware<any, any, any>[] = [], reducerMiddlewares: ReducerMiddleware[] = []) {
    if (Object.keys(apiReducers).length > 0) {
        const apiRootReducer = reducerMiddlewares.reduce((combine, func) => func(combine), combineReducers(apiReducers))
        return createStoreRedux(combineReducers({ ...storesNews, api: apiRootReducer }), compose(applyMiddleware(...middleWare)))
    }

    return createStoreRedux(combineReducers(storesNews), compose(applyMiddleware(...middleWare, ...middlewares)))
}