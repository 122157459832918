import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReducerType } from '../store'
import { initAction } from '../store/actions/sessionActions'

export function useSelectorSession() {
  return useSelector((state: ReducerType) => {
    return state.session
  })
}

export default function useInitApp() {
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(initAction())
  }, [dispatch])

  return useSelector((state: ReducerType) => {
    return state.session
  })
}