import React from 'react'
import SummaryContainer from '../containers/SummaryContainer';

export interface SummaryViewProps {
  
}
 
const SummaryView: React.FC<SummaryViewProps> = () => {
  return (
    <SummaryContainer />
  );
}
 
export default SummaryView;