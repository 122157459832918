import React, {  useCallback, useMemo, useRef, useState } from 'react'
import { Button, createStyles, makeStyles, TextField, Theme } from '@material-ui/core'
import GenericDialog from '../generics/GenericDialog'
import { FitType } from '../../models/Fit';
import { DiagnosticType, Media } from '../../models/Analysis';
import useVideoPlayer from '../../hooks/useVideoPlayer';
// ICONS
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PauseIcon from '@material-ui/icons/Pause';
import Rotate90DegreesCcwIcon from '@material-ui/icons/Rotate90DegreesCcw';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 30,
    },
    form: {
    },
    input: {
      marginBottom: 20
    },
    media: {
      objectFit: 'contain',
      height: 400,
      marginBottom: 20,
    },
    btnContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: 'flex-end',
    },
    btn: {
      width: 100
    },
    lastBtn: {
      marginLeft: 10
    },
    videoWrapper : {
      height:'100%',
      position:'relative',
    },
    image:{
      height:400,
      objectFit:'contain',
      width:'100%'
    },
    video: {
      height:'100%',
      width:'100%'
    },
    controlsContainer:{
      opacity:0.5,
      position:'absolute',
      bottom:0,
      left:0,
      width:'100%',
      transition:'opacity .2s ease-in-out',
      zIndex:2,
      display:'flex',
      alignItems:'center',
      '&:hover':{
        opacity:1
      },
      '& input':{
        flex:'1 1 100%',
        background: theme.palette.primary.light,
        '-webkit-appearance': 'none',
        appearance: 'none',
        outline: 'none',
        height:4,
        '&::-webkit-slider-thumb':{
          '-webkit-appearance': 'none', /* Override default look */
          appearance: 'none',
          width: 15, /* Set a specific slider handle width */
          height: 15, /* Slider handle height */
          background: theme.palette.primary.main, /* Green background */
          cursor: 'pointer', /* Cursor on hover */
          borderRadius:'100%'
        },
        '&::-moz-range-thumb': {
          width: 25, /* Set a specific slider handle width */
          height: 25, /* Slider handle height */
          background: '#04AA6D', /* Green background */
          cursor: 'pointer', /* Cursor on hover */
        }
      },
      '& svg':{
        '&:hover':{
          color:theme.palette.primary.main
        }
      }
    },
    volume:{
      position:'relative',
      display:'flex',
      alignItems:'center',
      '&:hover input':{
        display:'unset',
        opacity:1
      },
      '& input':{
        display:'none',
        opacity:0,
        transition:'opacity 0.3s ease-in-out',
        transform:'rotate(-90deg) translate(0px,-50%)',
        transformOrigin:' 0 0',
        position:'absolute',
        top:0,
        left:'50%',
        width:100
      }
    },
  }),
);

type Common = {
  fit: FitType,
  media: Media,
  isVideo?: boolean,
}

type NoEdit = {
  isEdit?: false
}

type Edit = {
  isEdit: true,
  diagnostic: DiagnosticType
}

export type DiagnosticOptions = Common & (NoEdit | Edit)

type DiagnosticDialogProps = {
  data: DiagnosticOptions,
  open: boolean,
  onSubmit: (diagnostic: DiagnosticType) => void,
  onClose: () => void,
}

const TITLE = "Diagnostic"

const DiagnosticDialog: React.FC<DiagnosticDialogProps> = (props) => {
  const {open, data, onClose, onSubmit} = props
  const titleRef = useRef<any>(null)
  const descriptionRef = useRef<any>()
  const videoRef = useRef<HTMLVideoElement>(null)
  const classes = useStyles()
  const [rotation, setRotation] = useState(0);
  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (titleRef.current && descriptionRef.current) {
      onSubmit({
        title: titleRef.current.value,
        description: descriptionRef.current.value,
        media: data.media
      })
    }
  }

  const defaultTitleValue = useMemo(() => {
    if (!props.data.isEdit) return ""
    return props.data.isEdit && props.data.diagnostic.title
  }, [props.data])

  const defaultDescriptionValue = useMemo(() => {
    if (!props.data.isEdit) return ""
    return props.data.isEdit && props.data.diagnostic.description
  }, [props.data])

  const isFinished = props.data.fit.analysis?.finished;

  /** MEDIA CONTROLS */
  const {handleOnTimeUpdate,
    togglePlay,
    handleVideoProgress,
    handleOnEnded,
    handleChangeVolume,
    handleOnVolumeChange,
    playerState
  } = useVideoPlayer(videoRef)
  const handleRotate = useCallback(()=>{
    setRotation((270+rotation)%360)
  },[rotation])
  return (
      <GenericDialog title={TITLE} open={open} onClose={onClose}>
        <div className={classes.container}>
          <div className={classes.media}>
            {data.isVideo ?
              <div className={classes.videoWrapper}>
                <video
                  className={classes.video}
                  style={{transform:`rotate(${rotation}deg)`,color:'red'}}
                  ref={videoRef}
                  src={data.media.url}
                  onEnded={handleOnEnded}
                  onTimeUpdate={handleOnTimeUpdate}
                  onVolumeChange={handleOnVolumeChange}
                />
                <div className={classes.controlsContainer}>
                  {!playerState.isPlaying ? <PlayCircleOutlineIcon onClick={togglePlay}/> :  <PauseIcon onClick={togglePlay}/>}
                  <input type="range" min="0" max="100" value={playerState.progress} onChange={handleVideoProgress}></input>
                  <div className={classes.volume}>
                    <VolumeUpIcon/>
                    <input type="range" min="0" max="1" step='0.01' value={playerState.volume} onChange={handleChangeVolume}></input>
                  </div>
                  <Rotate90DegreesCcwIcon onClick={handleRotate}/>

                </div>
              </div>
            : <img className={classes.image} src={data.media.url} alt={data.media.title} />
            }
          </div>
          <form className={classes.form} autoComplete="off" onSubmit={handleSubmit}>
            <TextField id="title" inputRef={titleRef} name={"title"} label="Title" variant="outlined" defaultValue={defaultTitleValue} fullWidth className={classes.input} disabled={isFinished} required/>
            <TextField id="description" inputRef={descriptionRef} name={"description"} label="Description" defaultValue={defaultDescriptionValue} variant="outlined" fullWidth multiline required disabled={isFinished} className={classes.input}/>
            <div className={classes.btnContainer}>
              {isFinished ?
                <Button color="secondary" variant="outlined" className={classes.btn} onClick={onClose} type="reset">CLOSE</Button>
                : (
                <>
                  <Button color="secondary" variant="outlined" className={classes.btn} onClick={onClose} type="reset">CANCEL</Button>
                  <Button color="primary" variant="outlined" className={classes.btn && classes.lastBtn} type="submit">SAVE</Button>
                </>
              )}
            </div>
          </form>
        </div>
      </GenericDialog>
  );
}

export default DiagnosticDialog;