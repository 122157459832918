import { AnalysisType, Media } from "./Analysis";
import { BikeType } from "./Bike";
type QuestionType = {
  title: string;
  value: string;
  id: number;
};
export enum PLAN_ENUM {
  QUICK = "QUICK",
  PRO = "PRO",
}
export type FitType = {
  id: number;
  planType: PLAN_ENUM;
  date: string; //| null,
  sessionDate: number;
  bikeId: number;
  analysis: AnalysisType | null;
  paid: boolean; //| null,
  purchase: null | any;
  fitterId: number;
  images: Media[]; //| null,
  needs: string[];
  status: string; //| null,
  value: number; //| null,
  videos: Media[]; //| null
  bike: BikeType;
  questions: QuestionType[];
};
