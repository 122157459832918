import React from "react"
import { Button, Card, CardActions, CardContent, CardMedia, Collapse, createStyles, IconButton, makeStyles, Typography } from "@material-ui/core"
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { red } from "@material-ui/core/colors"
import { FitType } from "../../models/Fit";
import { DiagnosticType, Media, VideoKeys } from "../../models/Analysis";
import { DiagnosticOptions } from "./DiagnosticDialog";
import { Add, Edit, Visibility } from "@material-ui/icons";
import fieldDiagnosticConfig from "../../config/fieldDiagnosticConfig";

type Props = {
  fit: FitType,
  media: Media,
  //user: UserType
  diagnostic?: DiagnosticType
  isVideo?: boolean
  className?: string | string[],
  onOpenDiagnostic: (options: DiagnosticOptions) => void
}

function MediaCardComponent(props: Props) {
  const classes = useStyles()

  const [expanded, setExpanded] = React.useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleOpenDiagnosticDialog = () => {
    const edit: { isEdit: true, diagnostic: DiagnosticType } | { isEdit: false } = props.diagnostic ? {
      isEdit: true,
      diagnostic: props.diagnostic
    } : {
      isEdit: false
    }
    props.onOpenDiagnostic({
      fit: props.fit,
      media: props.media,
      isVideo: props.isVideo,
      ...edit
    })
  }

  return (
    <Card className={clsx([classes.root, props.className])}>
      <CardMedia
        className={classes.media}
        image={props.media.url}
        component={props.isVideo ? 'video' : 'img'}
      //title={}
      />
      <CardContent className={classes.cardContent}>
        <Typography className={classes.cardTitle}>
          {props.media.title ? fieldDiagnosticConfig[props.media.title as VideoKeys] ?? props.media.title : "Title"}
        </Typography>
      </CardContent>
      <CardActions disableSpacing className={classes.cardActions}>
        {!props.fit.analysis?.finished &&
          <Button
            onClick={handleOpenDiagnosticDialog}
            variant="outlined"
            startIcon={props.diagnostic ? <Edit /> : <Add />}
          >
            {props.diagnostic ? 'EDIT' : 'ADD'}
          </Button>
        }
        {props.fit.analysis?.finished &&
          <Button
            onClick={handleOpenDiagnosticDialog}
            variant="outlined"
            startIcon={<Visibility/>}
          >
            VIEW
          </Button>
        }
        {props.diagnostic && <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>}
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {props.diagnostic && <CardContent>
          <Typography className={classes.diagnosticTitle}>
            {props.diagnostic.title}
          </Typography>
          <Typography className={classes.diagnosticDescription}>
            {props.diagnostic.description}
          </Typography>
        </CardContent>}
      </Collapse>
    </Card>
  );
}
const useStyles = makeStyles(theme => createStyles({
  root: {
    width: 200,
    marginRight: 20,
    marginBottom: 20,
    [theme.breakpoints.down('md')]: {
      marginRight: 10,
      marginBottom: 10,
    }
  },
  media: {
    overflowY: 'hidden',
    objectFit: 'cover',
    height: 125,
  },
  cardTitle: {
    fontSize: 16,
    textTransform: 'uppercase',
    padding: 0,
  },
  cardContent: {
    padding: 10,
    paddingBottom: 0,
  },
  cardActions: {
    padding: 10,
  },
  diagnosticTitle: {
    fontWeight: "bold"
  },
  diagnosticDescription: {

  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}))

export default MediaCardComponent