import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import useRouteMapName from '../hooks/useRouteMapName';
import { ClickAwayListener, Divider, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import { useSelectorSession } from '../hooks/useInitApp';
import ModifyFitterDialog from './ModifyFitterDialog';
import { logoutAction } from '../store/actions/sessionActions';
import { useDispatch } from 'react-redux';

const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = (theme: Theme) =>
  createStyles({
    secondaryBar: {
      zIndex: 0,
    },
    menuButton: {
      marginLeft: -theme.spacing(1),
    },
    iconButtonAvatar: {
      padding: 4,
    },
    link: {
      textDecoration: 'none',
      color: lightColor,
      '&:hover': {
        color: theme.palette.common.white,
      },
    },
    button: {
      borderColor: lightColor,
    },
  });

interface HeaderProps extends WithStyles<typeof styles> {
  onDrawerToggle: () => void;
}

function Header(props: HeaderProps) {
  const { classes, onDrawerToggle } = props;
  const { user: fitter } = useSelectorSession()
  const routeName = useRouteMapName()
  const anchorRef = React.useRef<any>()
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const dispatch = useDispatch()
  
  const handleClose = React.useCallback(() => {
    setOpen(false)
  }, [setOpen])
  
  function handleLogout() {
    dispatch(logoutAction())
  }

  if (!fitter) return <div></div>
  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Hidden smUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs>
              <Typography color="inherit" variant="h6" component="h1">
                {routeName}
              </Typography>
            </Grid>

            <Grid item>
              <Typography>{fitter?.firstName}</Typography>
            </Grid>
            <Grid item>
              <IconButton ref={anchorRef} color="inherit" className={classes.iconButtonAvatar} onClick={() => setOpen(true)}>
                <Avatar src={fitter?.avatar} alt={fitter?.firstName} />
              </IconButton>
              <Popper open={open} anchorEl={anchorRef.current} placement='left-start' role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList autoFocusItem={open} id="menu-list-grow" >
                          <>
                            <MenuItem onClick={() => setOpenDialog(true)} >My account</MenuItem>
                            <Divider />
                          </> 
                          <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <ModifyFitterDialog fitter={fitter} onClose={() => setOpenDialog(false)} open={openDialog} />
    </React.Fragment>
  );
}

export default withStyles(styles)(Header);