import React, { useState } from 'react'
import { createStyles, Divider, makeStyles, Typography } from '@material-ui/core';
import MediaCardComponent from '../../components/analysis/MediaCardComponent';
import DiagnosticDialogContainer from './DiagnosticDialogContainer';
import { DiagnosticOptions } from '../../components/analysis/DiagnosticDialog';
import { FitType } from '../../models/Fit';

type DiagnosticContainerProps = {
  fit: FitType | null
}

export default function DiagnosticContainer(props: DiagnosticContainerProps) {
  const {fit} = props
  const classes = useStyles()
  //const {} = userModel.uses.useFetchById(fit.)
  const images = React.useMemo(() => fit ? fit.images : [], [fit])
  const videos = React.useMemo(() => fit ? fit.videos : [], [fit])
  const [optionsDialog, setOptionsDialog] = useState<DiagnosticOptions | null>(null)

  const handleCloseDialog = () => {
    setOptionsDialog(null)
  }

  const handleSetOptionsDialog = (options: DiagnosticOptions) => {
    setOptionsDialog(options)
  }

  return <div>
    {optionsDialog &&
      <DiagnosticDialogContainer onClose={handleCloseDialog} data={optionsDialog}/>
    }
    <Typography variant='h5' color='textSecondary'>Images</Typography>
    <div className={classes.imageContainer}>
      {fit && images.map(image => (
        <MediaCardComponent
          className={classes.card}
          key={image.id}
          media={image}
          fit={fit}
          onOpenDiagnostic={handleSetOptionsDialog}
          diagnostic={fit.analysis?.diagnostics.find(diag => diag.media.id === image.id)} 
        />
      ))}

    </div>
    <Divider className={classes.divider}/>
    <Typography variant='h5' color='textSecondary'>Videos</Typography>
    <div className={classes.videoContainer}>
      {fit && videos.map(video => (
        <MediaCardComponent
          className={classes.card}
          key={video.id}
          media={video}
          fit={fit}
          isVideo
          onOpenDiagnostic={handleSetOptionsDialog}
          diagnostic={fit.analysis?.diagnostics.find(diag => diag.media.id === video.id)} 
        />
      ))}
    </div>
  </div>
}
const useStyles = makeStyles(theme => createStyles({
  imageContainer: {
    display: 'flex',
    overflowX: 'auto',
    marginBottom: 20,
    marginTop: 20,
  },
  videoContainer: {
    display: 'flex',
    overflowX: 'auto',
    marginBottom: 20,
    marginTop: 20
  },
  card: {
    marginRight: 20,
    [theme.breakpoints.down('md')]: {
      marginRight: 10,
    }
  },
  divider: {
    marginTop: 20,
    marginBottom: 20
  }
}))