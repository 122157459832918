import React, { useState } from 'react'
import { Appointments, MonthView, DayView, WeekView, Scheduler, ViewSwitcher, Toolbar as ToolbarCalendar, DateNavigator, TodayButton, AppointmentTooltip, EditRecurrenceMenu, DragDropProvider, Resources } from '@devexpress/dx-react-scheduler-material-ui';
import { Toolbar, Paper, makeStyles, createStyles, Fab } from '@material-ui/core';
import { ChangeSet, EditingState, IntegratedEditing, Resource, ViewState } from '@devexpress/dx-react-scheduler';
import { ENUM_ROUTES, FitrRouteComponentProps } from '../routes';
import { sessionModel } from '../store';
import moment from 'moment'
import { AddCircleOutline } from '@material-ui/icons';
import SessionDialog from '../components/SessionDialog';
import { FitrAppointmentModel, FormSessionType, SessionType } from '../models/Session';
import { api } from '../services';
import { isEmptyIntersection } from '../utils/date';
import Appointment from '../components/calendar/Appointment';
import FitrAppointmentContent from '../components/calendar/AppointmentContent';
import AppointmentHeader from '../components/calendar/AppointmentHeader';

const mainResourceName = 'meetingUrl'
const resource: (Resource & { fieldName: keyof SessionType }) = {
  fieldName: 'meetingUrl',
  title: 'Zoom link',
  instances: []
}

function FitrCalendarView(props: FitrRouteComponentProps<ENUM_ROUTES.CALENDAR>) {

  const classes = useStyles()
  const { result: sessions, reduxListener, setInvalidate } = sessionModel.uses.useFetch({})
  const [selectedDay, setSelectedDay] = useState(new Date())
  const [open, setOpen] = useState(false)
  const [appointments, setAppointments] = useState<FitrAppointmentModel[]>([]);
  const [loading, setLoading] = useState(false)
  const [currentViewName, setCurrentViewName] = useState('Month');

  React.useEffect(() => {
    const newAppointment: FitrAppointmentModel[] = !sessions ? [] : sessions.map((session) => ({
      ...session,
      id: session.id,
      startDate: moment(session.startDate).toDate(),
      endDate: moment(session.endDate).toDate(),
      title: !session.booked ? 'Open session' : 'Fit ' + session.fitId,
    }), {})
    setAppointments(newAppointment)
    //we can use sessions because sessions ir a map, so always is a new object
    //eslint-disable-next-line 
  }, [reduxListener])

  const handleSubmitSession = React.useCallback((sessions: FormSessionType[]) => {
    setLoading(true)
    Promise.all(sessions.map(session => api.createSession(session)))
      .then(() => {
        setInvalidate({})
        setLoading(false)
        setOpen(false)
      }).catch(err => {
        console.error(err)
        setLoading(true)
        setOpen(false)
      })
  }, [setInvalidate])

  const handleEdit = React.useCallback((changeds: FitrAppointmentModel[]) => {
    //filter changed data started Date > today and is not booked. Put loging in titile
    const filteredChanged = changeds
      .filter(date => !date.booked && (new Date(date.startDate)).getTime() > Date.now())
      .map(element => ({ ...element, title: 'loading...' }))
    if (filteredChanged.length === 0) return
    // create backup 
    const lastAppointment = [...appointments]
    const filteredAppointment = appointments
      .filter(app => filteredChanged.findIndex(changedApp => Number(app.id) === Number(changedApp.id)) === -1)
    // Validate if there are another date in DB with the same date 
    if (filteredChanged.some(change => filteredAppointment.some(app => !isEmptyIntersection([change.startDate as Date, change.endDate as Date], [app.startDate as Date, app.endDate as Date]))))
      return

    setAppointments([
      ...filteredAppointment,
      ...filteredChanged
    ])
    Promise.all(filteredChanged.map(newDate => new Promise((resolve, reject) => {
      let currentDate = appointments.find(app => app.id === Number(newDate.id))
      if (currentDate) {
        let startDate = new Date(newDate.startDate)
        let endDate = new Date(newDate.endDate)

        let currentStart = new Date(currentDate.startDate)
        let currentEnd = new Date(currentDate.endDate)

        const start = currentViewName !== 'Month' ? startDate : currentStart
        const end = currentViewName !== 'Month' ? endDate : currentEnd

        let session: FormSessionType = {
          startDate: start,
          endDate: end
        }
        if (start === end) return reject(new Error('start date is equal to end date'))
        api.putSession(Number(currentDate.id), session).then(resolve).catch(reject)
      }
      else
        return resolve(null)
    }))).then(() => {
      setInvalidate({})
    }).catch(err => {
      console.error(err)
      setAppointments(lastAppointment)
    })
  }, [appointments, currentViewName, setAppointments, setInvalidate])

  const handleAdd = React.useCallback((changed: FitrAppointmentModel[]) => {



  }, [])

  const handleDelete = React.useCallback((deleted: number) => {
    if (sessions) {
      const currentDate = sessions.find(app => app.id === Number(deleted))
      if (currentDate && !currentDate.booked) {
        api.deleteSession(currentDate.id).then(() => {
          setInvalidate({})
        })
      }
    }


  }, [sessions, setInvalidate])
  const commitChanges = React.useCallback(({ added, changed, deleted }: ChangeSet) => {
    if (added) handleAdd(getAppointmentModel(added))
    if (changed) handleEdit(getAppointmentModel(changed))
    if (deleted) handleDelete(deleted as number)
  }, [handleAdd, handleEdit, handleDelete])

  return <div >
    <Paper className={classes.toolbarFilter}>
      <Toolbar color='transparent'>
      </Toolbar>
    </Paper>
    <Paper>

      <Scheduler
        data={appointments}
      >

        <ViewState
          defaultCurrentViewName='Month'
          currentViewName={currentViewName}
          onCurrentViewNameChange={view => setCurrentViewName(view)}
          currentDate={selectedDay}
          onCurrentDateChange={setSelectedDay}
        />
        <EditingState
          onCommitChanges={commitChanges}

        />
        <EditRecurrenceMenu />
        <IntegratedEditing />
        <DayView />
        <WeekView />
        <MonthView />

        <ToolbarCalendar />
        <DateNavigator />
        <TodayButton />
        <ViewSwitcher />
        <Appointments appointmentComponent={Appointment} />
        <AppointmentTooltip
          //showOpenButton
          showDeleteButton
          contentComponent={FitrAppointmentContent}
          headerComponent={AppointmentHeader}
        />
        <Resources
          data={[resource]}
          mainResourceName={mainResourceName}

        />
        <DragDropProvider
          allowDrag={({ booked }) => !booked}

        />
        {/**<AppointmentForm />*/}
      </Scheduler>

    </Paper>

    <Fab className={classes.fab} color='primary' onClick={() => setOpen(true)}>
      <AddCircleOutline />
    </Fab>
    <SessionDialog
      appointments={appointments}
      loading={loading}
      open={open}
      day={selectedDay}
      onClose={() => setOpen(false)}
      onSubmitSesion={handleSubmitSession} />
  </div>
}
// STYLES
const useStyles = makeStyles(theme => createStyles(({
  toolbarFilter: {
    marginBottom: 20
  },
  flex: {
    flex: 1
  },

  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
})))
// UTILS
function getAppointmentModel(obj: ChangeSet['changed']): FitrAppointmentModel[] {
  if (obj)
    return Object.keys(obj).map(id => ({ id, ...obj[id] }))
  return []
}
export default FitrCalendarView