// @src/hooks/useVideoPlayer.js

import { useState, useEffect, ChangeEventHandler } from "react";

const useVideoPlayer = (videoElement:React.RefObject<HTMLVideoElement>) => {
  const [playerState, setPlayerState] = useState({
    isPlaying: false,
    progress: 0,
    speed: 1,
    isMuted: false,
    volume:1
  });
  const handleOnVolumeChange = ()=>{
    if(!videoElement.current) return console.warn('[use video player] current not found')
    setPlayerState({
      ...playerState,
      volume:videoElement.current.volume
    })
  }
  const handleChangeVolume:ChangeEventHandler<HTMLInputElement> = (event) => {
    if(!videoElement.current) return console.warn('[use video player] current not found')
    const value = Number(event.target.value)
    console.log(videoElement.current.volume)
    videoElement.current.volume = value
    setPlayerState({
      ...playerState,
      volume:value
    })
  }
  const togglePlay = () => {
    setPlayerState({
      ...playerState,
      isPlaying: !playerState.isPlaying,
    });
  };

  useEffect(() => {
    if(!videoElement.current) return console.warn('[use video player] current not found')
    playerState.isPlaying
      ? videoElement.current.play()
      : videoElement.current.pause();
  }, [playerState.isPlaying, videoElement]);

  const handleOnTimeUpdate = () => {
    if(!videoElement.current) return console.warn('[use video player] current not found')
    const progress = (videoElement.current.currentTime / videoElement.current.duration) * 100;
    setPlayerState({
      ...playerState,
      progress,
    });
  };

  const handleVideoProgress:ChangeEventHandler<HTMLInputElement> = (event) => {
    if(!videoElement.current) return console.warn('[use video player] current not found')
    const manualChange = Number(event.target.value);
    videoElement.current.currentTime = (videoElement.current.duration / 100) * manualChange;
    setPlayerState({
      ...playerState,
      progress: manualChange,
    });
  };

  const handleVideoSpeed:ChangeEventHandler<HTMLInputElement> = (event) => {
    if(!videoElement.current) return console.warn('[use video player] current not found')
    const speed = Number(event.target.value);
    videoElement.current.playbackRate = speed;
    setPlayerState({
      ...playerState,
      speed,
    });
  };

  const toggleMute = () => {
    setPlayerState({
      ...playerState,
      isMuted: !playerState.isMuted,
      volume: 0
    });
  };

  const handleOnEnded = () => {
    if(!videoElement.current) return console.warn('[use video player] current not found')
    videoElement.current.pause()
    videoElement.current.currentTime = 0
    setPlayerState({
      ...playerState,
      isPlaying: false
    });
  }

  useEffect(() => {
    if(!videoElement.current) return console.warn('[use video player] current not found')
    playerState.isMuted
      ? (videoElement.current.muted = true)
      : (videoElement.current.muted = false);
  }, [playerState.isMuted, videoElement]);

  return {
    playerState,
    togglePlay,
    handleOnEnded,
    handleOnTimeUpdate,
    handleVideoProgress,
    handleVideoSpeed,
    handleOnVolumeChange,
    handleChangeVolume,
    toggleMute,
    
  };
};

export default useVideoPlayer;