const USER_ID = 'user_id'
const TOKEN = 'token'

class LocalStorageContainer {
  constructor() {
    this.validateLocalStore()
  }
  /**
   * function for validate storage
   */
  private validateLocalStore() {
  }
  setUserId(userId: number) {
    localStorage.setItem(USER_ID, userId?.toString())
    return this
  }
  clearUserId() {
    localStorage.removeItem(USER_ID)
    return this
  }
  get userId(): number | null {
    var userId = localStorage.getItem(USER_ID)
    return userId ? Number(userId) : null
  }
  setToken(token: string) {
    localStorage.setItem(TOKEN, token)
    return this
  }
  clearToken() {
    localStorage.removeItem(TOKEN)
    return this
  }
  get token(): string | null {
    var token = localStorage.getItem(TOKEN)
    return token ?? null
  }
}

export default new LocalStorageContainer()