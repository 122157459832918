import { createStyles, fade, makeStyles } from "@material-ui/core";

export default makeStyles(theme => createStyles({
  bookedDay: {
    backgroundColor: fade(theme.palette.primary.main, 0.9),
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.4),
    },
    '&:focus': {
      backgroundColor: fade(theme.palette.primary.main, 0.6),
    },
  },
  notPaid: {
    backgroundColor: fade(theme.palette.grey[500], 0.6),
    '&:hover': {
      backgroundColor: fade(theme.palette.grey[500], 0.4),
    },
    '&:focus': {
      backgroundColor: fade(theme.palette.grey[500], 0.5),
    },
  },
  day: {
    backgroundColor: fade(theme.palette.secondary.dark, 0.6),
    '&:hover': {
      backgroundColor: fade(theme.palette.secondary.dark, 0.4),
    },
    '&:focus': {
      backgroundColor: fade(theme.palette.secondary.dark, 0.5),
    },
  }
}))