import moment from "moment";

export var monthLargeEn = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export var monthShortEn = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
export var weekEn = ['Sun', 'Mon', 'Tue', 'Wen', 'Thu', 'Fri', 'Sat']

export function getFormatedDate(date: Date, format: 'DD/MM/YYYY' | 'YYYY-MM-DD' = 'DD/MM/YYYY') {
    switch (format) {
        case 'DD/MM/YYYY': return `${date.getDate()} / ${monthShortEn[date.getMonth()]} / ${date.getUTCFullYear()}`
        case 'YYYY-MM-DD': return getFortatedDateYYYYMMDD(date)
    }

}
function formatTwoDecimal(number: number) {
    return number / 10 < 1 ? `0${number}` : number.toString()
}
function getFortatedDateYYYYMMDD(date: Date) {
    return `${date.getUTCFullYear()}-${formatTwoDecimal(date.getMonth() + 1)}-${formatTwoDecimal(date.getDate())}`
}
export function isEmptyIntersection(inverval1: [Date, Date], inverval2: [Date, Date]) {
    const [start, end] = inverval1
    const [startAppointment, endAppointment] = inverval2
    return (start.getTime() < startAppointment.getTime() && end.getTime() <= startAppointment.getTime())
        || (start.getTime() >= endAppointment.getTime() && end.getTime() > endAppointment.getTime())

    /*    return (start.getTime() >= startAppointment.getTime() && start.getTime() < endAppointment.getTime())
        || (end.getTime() >= startAppointment.getTime() && end.getTime() <= endAppointment.getTime())
        || (start.getTime() <= startAppointment.getTime() && end.getTime() >= endAppointment.getTime())
        */
}
/**
 * @example param new Date(2020,10,10,9) => 9 am. Other example: new Date(2020,10,10,13) => 9 am
 * @param date Date
 */
export function getHoursAmPm(date: Date) {
    let hours = date.getHours()
    var hoursAmPm = hours % 12
    hoursAmPm = hoursAmPm === 0 ? 12 : hoursAmPm
    return hoursAmPm + (hours / 12 < 1 ? ' am' : ' pm')
}

export function timeSince(date: Date) {

    var seconds = Math.floor((Date.now() - date.getTime()) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
}

export function currentWeek(): { startDate: string, endDate: string } {
    const curr = new Date();
    const first = curr.getDate() - curr.getDay();
    const last = curr.getDate() - (curr.getDay() - 1) + 6;

    const firstDay = new Date(curr.setDate(first));
    const lastDay = new Date(curr.setDate(last));

    if (firstDay > lastDay) {
        lastDay.setMonth(lastDay.getMonth() + 1)
    }

    return {
        startDate: moment(firstDay).format('DD-MM-YYYY'),
        endDate: moment(lastDay).format('DD-MM-YYYY'),
    }
}

export function currentMonth(): { startDate: string, endDate: string } {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return {
        startDate: moment(firstDay).format('DD-MM-YYYY'),
        endDate: moment(lastDay).format('DD-MM-YYYY'),
    }
}

export function currentYear(): { startDate: string, endDate: string } {
    const date = new Date();
    const firstDay = new Date(date.getFullYear() - 1, 12);
    const lastDay = new Date(new Date().getFullYear(), 11, 31);
    return {
        startDate: moment(firstDay).format('DD-MM-YYYY'),
        endDate: moment(lastDay).format('DD-MM-YYYY'),
    }
}