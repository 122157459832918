import React from 'react'
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { AdjustmentType } from '../../../models/Adjustment';
import AdjustmentCardComponent from './AdjustmentCardComponent';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    justifyContent: "center"
  },
  content: {
    textAlign: "center",
    marginLeft: 40,
    marginRight: 40
  },
  title: {
    fontSize: 26
  },
  description: {
    fontSize: 20,
    color: 'red'
  },
  stepBlock: {

  },
  stepText: {
    fontSize: 20,
  }
}))

export interface ModificationResumeProps {
  adjustment: AdjustmentType | null,
  steps: string[]
}
 
const ModificationResume: React.FC<ModificationResumeProps> = (props) => {
  const {adjustment, steps} = props
  const classes = useStyles()

  return ( 
    <div className={classes.container}>
      <div className={classes.content}>
        <Typography className={classes.title}>Selected adjustment</Typography>
        {
          adjustment ?
            <AdjustmentCardComponent adjustment={adjustment} selected onSelect={() => {}}/>
          :
          <Typography className={classes.description}>Please select an adjustment before finish (Step 1)</Typography>
        }
      </div>
      <div className={classes.content}>
        <Typography className={classes.title}>Step by step</Typography>
        {
          steps.length > 0 ?
          <div className={classes.stepBlock}>
            {
              steps.map((step, index) => (
                <Typography className={classes.stepText} key={index}>{`${index +1}. ${step}`}</Typography>
              ))
            }
          </div>
          :
          <Typography className={classes.description}>Please introduce the modification steps before finish (Step 2)</Typography>
        }
      </div>
    </div>
  );
}
 
export default ModificationResume;