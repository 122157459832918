import { createStyles, fade, makeStyles, Table, TableBody, TableCell, TableContainer, Toolbar } from '@material-ui/core'
import React from 'react'
import GenericTableRow from '../components/generics/GenericTableRow'
import { FitType, PLAN_ENUM } from '../models/Fit'
import { adminFitsModel, adminProFitsModel, fitModel } from '../store'
import GenericTableHeader from '../components/generics/GenericTableHeader'
import { FilterObject } from '../utils/types'
import useSortTable from '../hooks/useSortTable'
import clsx from 'clsx'
import FitterSelectorContainer from '../containers/Selectors/FitterSelectorContainer'
import { api } from '../services'

type FitTableType = {
    finished?: string
    kind?: string,
    plan?: string
    fitter?: string
} & FilterObject<FitType, string | number>

export default function ManageFitsView({ type }: { type: PLAN_ENUM }) {
    const reducerModel = type === PLAN_ENUM.PRO ? adminProFitsModel : adminFitsModel;

    const { result: fits, setInvalidate: setInvalidateFitAdmin } = reducerModel.uses.useFetch({})
    const { setInvalidateAll } = fitModel.uses.useFetch({})
    const classes = styles()

    const handleSelectFitter = React.useCallback((fitId: number, fitterId: number) => {
      api.patchFitWithFitterId(fitId, fitterId).then(res => {
        setInvalidateFitAdmin(fitId)
        setInvalidateAll()
      })
    }, [setInvalidateFitAdmin, setInvalidateAll])

    const fitMaped = React.useMemo<FitTableType[]>(() => !fits ? [] : fits.filter(fit => fit.paid).map(fit => ({
        ...fit,
        kind: fit.bike.kind,
        finished: !!fit.analysis ? fit.analysis.finished ? 'FINISHED' : 'IN PROGRESS' : ''
    })), [fits])
    const { sortedData, handleRequestSort, order, orderBy } = useSortTable(fitMaped, 'id')

    return <div>
        <Toolbar>
            {/** FIlters */}
        </Toolbar>
        <TableContainer>
            <Table size='small' >
                <GenericTableHeader
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    headCells={[{
                      id: 'id',
                      label: 'Id',
                      numeric: true,
                      align: "center"
                    }, {
                      id: 'planType',
                      label: 'Plan',
                      align: "center"
                    },
                    {
                      id: 'date',
                      label: 'Date',
                      align: "center"
                    },
                    {
                      id: 'kind',
                      label: 'Bike Kind',
                      align: "center"
                    },
                    {
                      id: 'finished',
                      label: 'Status',
                      align: 'center',
                    },
                    {
                      id: 'fitter',
                      label: 'Fitter',
                      align: "center"
                    }]}
                />
                <TableBody>
                    {sortedData.map((fit) => (
                        <GenericTableRow key={fit.id}>
                            <TableCell align="center" >{fit.id}</TableCell>
                            <TableCell className={clsx([classes.planCell, fit.planType === PLAN_ENUM.PRO ? classes.proCell : classes.quickCell])} align="center" >{fit.planType}</TableCell>
                            <TableCell align="center">
                                {fit.date}
                            </TableCell>
                            <TableCell align="center" >
                                {fit.kind}
                            </TableCell>
                            <TableCell align="center">
                                {fit.finished}
                            </TableCell>
                            <TableCell align="center">
                              <FitterSelectorContainer onSelect={(id: number) => handleSelectFitter(fit.id, id)} selectedValue={fit.fitterId}/>
                            </TableCell>
                            </GenericTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

    </div>
}
const styles = makeStyles((theme) => createStyles(
    {
        planCell: {
            color: 'black'
        },
        quickCell: {
            backgroundColor: fade(theme.palette.secondary.light, 0.4),
        },
        proCell: {
            backgroundColor: fade(theme.palette.primary.light, 0.4),
        }
    },

))
